export const PATH_PPT_EVENTOS_API = 'ppt-eventos';
export const PATH_CEP_API = 'cep';
export const PATH_VEICULO_API = 'veiculo';
export const PATH_MP_PATERNIDADE_API = 'mp-paternidade';
export const PATH_CIN = 'bcadastro-cin';
export const PATH_ATPVE_API = 'atpv';
export const PATH_EDUCACAO_SEDUC_RESPONSAVEIS_API = 'seduc-responsaveis';
export const PATH_EDUCACAO_SEDUC_DOMINIOS_API = 'seduc-dominios';
export const PATH_EDUCACAO_SEDUC_INSCRICOES_API = 'seduc-inscricoes';
export const PATH_EDUCACAO_SEDUC_FICHAS_API = 'seduc-fichas';
export const PATH_EDUCACAO_SEDUC_ALUNOS_API = 'seduc-alunos';
export const PATH_ARQUIVOS_API = 'arquivos';
export const PATH_BCADASTRO_API = 'bcadastro';
export const PATH_SGU_API = 'bu-ppt-dominios';

export const PATH_CDHU_MUTUARIOS_API = 'cdhu-mutuarios';
export const PATH_POUPA_FILA = 'poupafila';
