import styled, { css } from 'styled-components';

interface TitleProps {
	required: boolean;
	size?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	customWidth?: string;
}

interface ContentProps {
	size?: number;
	display: string;
	flexWrap: boolean;
	strong: boolean;
	isErrored: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	noTextTransform?: boolean;
}

interface ContainerProps {
	directionRow?: boolean;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: center;

	& + div {
	}

	${props =>
		props.directionRow &&
		css`
			align-items: flex-start;
			flex-direction: column;
		`};
`;

export const Title = styled.div<TitleProps>`
	margin-right: 10px;
	width: 100%;

	${props =>
		typeof props.customWidth === 'string' &&
		css`
			width: ${props.customWidth};
		`};

	${props =>
		props.size === 'sm' &&
		css`
			min-width: 80px;
		`};

	${props =>
		props.size === 'md' &&
		css`
			min-width: 120px;
		`};

	${props =>
		props.size === 'lg' &&
		css`
			min-width: 160px;
		`};

	${props =>
		props.size === 'xl' &&
		css`
			min-width: 200px;
		`};

	${props =>
		Number(props.size) &&
		css`
			min-width: ${`${props.size}px`};
		`};

	${props =>
		props.size === 'auto' &&
		css`
			width: auto;
		`};

	label {
		align-items: center;
		display: flex;
		font-size: calc(0.75rem + ${props => props.theme.fontScale}%);
		font-weight: 500;
		justify-content: flex-end;

		${props =>
			props.required &&
			css`
				::before {
					content: '*';
					color: red;
					font-size: calc(1rem + ${props.theme.fontScale}%);
					font-weight: 700;
					left: -4px;
					position: relative;
				}
			`};
	}
`;

export const Content = styled.div<ContentProps>`
	.ant-radio-group {
		${props =>
			props.display === 'horizontal' &&
			css`
				display: flex;
				height: 25px;
			`};

		${props =>
			props.flexWrap &&
			css`
				flex-wrap: wrap;
			`};

		${props =>
			props.display === 'vertical' &&
			css`
				display: block;
			`};

		label {
			align-items: center;
			display: flex;
			margin: auto 0;
			text-transform: uppercase;
			/* min-width: 95px; */

			span {
				align-self: center;
				color: ${props => props.theme.typography?.primary};
				font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
				white-space: nowrap;

				${props =>
					props.strong &&
					css`
						font-weight: 500;
					`};
			}

			span.ant-radio + * {
				padding-right: 15px;
				${props =>
					props.noTextTransform &&
					css`
						text-transform: none;
					`};
			}

			.ant-radio-inner {
				border-color: ${props => props.theme.colors?.border};
			}

			.ant-radio-checked {
				&::after {
					border: 1px solid #85b2cb;
				}

				.ant-radio-inner {
					border-color: #85b2cb;

					&::after {
						background-color: #85b2cb;
					}
				}
			}

			${props =>
				props.isErrored &&
				css`
					.ant-radio-input:focus + .ant-radio-inner {
						box-shadow: 0 0 0 3px rgba(204, 51, 51, 0.08);
					}

					.ant-radio-inner {
						border-color: rgb(204, 51, 51);

						&::after {
							background-color: rgb(204, 51, 51);
						}
					}
				`};
		}
	}
`;

export const SubTitle = styled.div`
	margin: auto 10px auto 5px;
	max-width: fit-content;
	width: 100%;

	span {
		font-size: calc(0.8rem + ${props => props.theme.fontScale}%);
		line-height: unset;
		padding: 4px;
		text-transform: uppercase;
		width: 100%;
	}
`;

export const RadioWithExternalTooltip = styled.div`
	display: flex;
	align-items: end;
	width: 120px;
`;
