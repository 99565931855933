import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarMunicipioIbgeRequest } from './types';

export function cadastrarMunicipioIbgeRequest(
	token: string,
	data: CadastrarMunicipioIbgeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_REQUEST,
		token,
		payload: data,
	};
}
export function cadastrarMunicipioIbgeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_SUCCESS,
		payload,
	};
}
export function cadastrarMunicipioIbgeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_FAILURE,
		payload: null,
	};
}
export function cadastrarMunicipioIbgeClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_IBGE_CLEAR,
		payload: null,
	};
}
