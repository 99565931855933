export enum Types {
	EXCLUIR_MUNICIPIO_IBGE_REQUEST = '@SGU/EXCLUIR_MUNICIPIO_IBGE',
	EXCLUIR_MUNICIPIO_IBGE_SUCCESS = '@SGU/EXCLUIR_MUNICIPIO_IBGE_SUCCESS',
	EXCLUIR_MUNICIPIO_IBGE_FAILURE = '@SGU/EXCLUIR_MUNICIPIO_IBGE_FAILURE',
	EXCLUIR_MUNICIPIO_IBGE_CLEAR = '@SGU/EXCLUIR_MUNICIPIO_IBGE_CLEAR',
}

export interface ExcluirMunicipioIbge {
	status: number;
	data: null | IExcluirMunicipioIbge;
}

export interface IExcluirMunicipioIbge {
	status: string;
	statusMensagem: string;
}

export interface ExcluirMunicipioIbgeRequest {
	idNaturalidade: string | number;
}
