import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_INSCRICOES_API } from 'services/rotasp/path';

import { RemoverInscricaoData, Types } from './types';
import { removerInscricaoSuccess, removerInscricaoFailure } from './actions';

function* removerInscricaoRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: RemoverInscricaoData } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_EDUCACAO_SEDUC_INSCRICOES_API,
		`inscricoes`,
		payload,
	);

	if (response.status === 200) {
		yield put(removerInscricaoSuccess(response));
	} else if (response.error) {
		yield put(removerInscricaoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.REMOVER_INSCRICAO_REQUEST, removerInscricaoRequest),
]);

