import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { ConsultaUsuarioPendente } from 'store/modules/api/aac/novoPainelDeControle/consultaUsuarioPendente/types';

// STYLED
import { formatDate } from 'utils/genericFunctions';
import { Content, P } from './styles';

interface Props {
	data: ConsultaUsuarioPendente;
}

const DadosRg: React.FC<Props> = ({ data }) => {
	const valRg =
		data.dc && data.dc.trim() !== ''
			? `${data.numeroRg?.trim()}-${data.dc}`
			: `${data.numeroRg?.trim()}`;
	return (
		<Row gutter={[0, 1]}>
			<Col span={24}>
				<Content>
					<p>Documento: </p>
					{data.tipoDocApresentado === 'CIN' && data.cpf ? (
						<P>{`${data.cpf?.trim()}-${data.dcCpf}`}</P>
					) : (
						<P>{`${valRg}`}</P>
					)}
				</Content>
			</Col>

			<Col span={24}>
				<Content>
					<p>Nome: </p>
					<P>{data.nome}</P>
				</Content>
			</Col>

			<Col span={24}>
				<Content>
					<p>Pai: </p>
					<P>{data.nomePai}</P>
				</Content>
			</Col>

			<Col span={24}>
				<Content>
					<p>Mãe: </p>
					<P>{data.nomeMae}</P>
				</Content>
			</Col>

			<Col span={24}>
				<Row gutter={[0, 1]}>
					<Col span={12}>
						<Content>
							<p>Sexo: </p>
							<P>{data.sexo === 'M' ? 'MASCULINO' : 'FEMININO'}</P>
						</Content>
					</Col>

					<Col span={12}>
						<Content>
							<p>Data de Nascimento: </p>
							<P>
								{data.dataNascimento
									? formatDate(data.dataNascimento.toString())
									: ''}
							</P>
						</Content>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default DadosRg;
