import React, { useCallback, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { registraAcoesUsoRequest } from 'store/modules/user/acoesUso/actions';

// COMPONENTS
import RecuperaAtendimento from 'components/Pages/RecuperaAtendimento';

const RecuperarAtendimento: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const { redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	useEffect(() => {
		if (redirecionamento) {
			dispatch(
				registraAcoesUsoRequest({
					clickedOptionPath:
						redirecionamento.charAt(0) === '/'
							? redirecionamento
							: `${location.pathname}/${redirecionamento}`,
					clickedOptionHasRecoveryService: {
						clickedOptionPath:
							redirecionamento.charAt(0) === '/'
								? redirecionamento
								: `${location.pathname}/${redirecionamento}`,
						clickedOptionHasRecoveryService: true,
					},
				}),
			);
		}
	}, [dispatch, location.pathname, redirecionamento]);

	const handleClick = useCallback(() => {
		dispatch(cdhuClear());
		if (redirecionamento) {
			history.push(redirecionamento);
		}
	}, [dispatch, redirecionamento, history]);

	return <RecuperaAtendimento onClick={handleClick} />;
};

export default RecuperarAtendimento;
