import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { eventosPPTContabilizacaoRequest } from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

import {
	ROUTE_CDHU_SERVICOS_SUCESSO,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
} from 'pages/cdhu/routes/paths';

// COMPONENTS
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Error from 'components/Common/Notifications/FormError';

// UTILS
import { treatRequestEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';
import { treatRequestSguResponseEventsCDHU } from 'pages/cdhu/utils/functions/sguStatistics/treatServiceRequest';

// SECTIONS
import {
	ACORDO,
	DESC_ORGAO,
	ID_ORGAO_CDHU,
} from 'pages/cdhu/utils/tiposEventos';
import DadosMutuario from './DadosMutuario';
import SituacaoContrato from './SituacaoContrato';
import AcordoPadrao from './AcordoPadrao';
import SimulacaoAcordo from './SimulacaoAcordo';

const Acordo: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { simularEfetuarAcordo, consultarMutuario } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(cdhuClear());
		history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
	}, [dispatch, history]);

	useEffect(() => {
		if (simularEfetuarAcordo.data?.mensagem) {
			setNotificationErrors([simularEfetuarAcordo.data.mensagem]);
		} else {
			setNotificationErrors([]);
		}
	}, [simularEfetuarAcordo.data]);

	useEffect(() => {
		if (
			simularEfetuarAcordo.request?.servicoEnum === 'EFETIVAR_ACORDO' &&
			(simularEfetuarAcordo.request?.tipoSimulacao === 2 ||
				simularEfetuarAcordo.request?.tipoSimulacao === 3 ||
				simularEfetuarAcordo.request?.tipoSimulacao === 4) &&
			simularEfetuarAcordo.status === 200
		) {
			if (eventosPPTContabilizacao.status === 0) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					consultarMutuario.data?.cpf || '',
					DESC_ORGAO,
					ID_ORGAO_CDHU,
					ACORDO.DESC,
					ACORDO.ID_SERVICO,
					ACORDO.TIPO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		}
	}, [
		simularEfetuarAcordo.status,
		simularEfetuarAcordo.request,
		eventosPPTContabilizacao.status,
		atendimento,
		loginUnico,
		dispatch,
		token,
		consultarMutuario.data,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status === 0 &&
			eventosPPTContabilizacao.status !== 0 &&
			eventosPPTContabilizacao.status !== 100
		) {
			const payload = treatRequestSguResponseEventsCDHU(
				loginUnico,
				atendimento,
				consultarMutuario.data?.cpf || '',
				eventosPPTContabilizacao.status === 200,
				ACORDO.DESC,
				ACORDO.TIPO,
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		eventosPPTContabilizacao.status,
		estatisticasIncluir.status,
		dispatch,
		atendimento,
		loginUnico,
		consultarMutuario.data,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status !== 0 &&
			estatisticasIncluir.status !== 100
		) {
			history.push(ROUTE_CDHU_SERVICOS_SUCESSO);
		}
	}, [estatisticasIncluir.status, history]);

	return (
		<>
			<Row>
				<Col span={24}>
					{notificationErrors.length > 0 && (
						<Error errors={notificationErrors} />
					)}
					<DadosMutuario />

					<SituacaoContrato />

					<AcordoPadrao />

					<SimulacaoAcordo />

					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</Col>
			</Row>
		</>
	);
};

export default Acordo;
