import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarMunicipiosRequest } from './types';

export function consultarMunicipiosRequest(
	token: string,
	data: ConsultarMunicipiosRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_REQUEST,
		token,
		payload: data,
	};
}
export function consultarMunicipiosSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function consultarMunicipiosFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_FAILURE,
		payload: null,
	};
}
export function consultarMunicipiosClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_CLEAR,
		payload: null,
	};
}
