import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, CadastrarMunicipioRequest } from './types';

export function cadastrarMunicipioRequest(
	token: string,
	data: CadastrarMunicipioRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_REQUEST,
		token,
		payload: data,
	};
}
export function cadastrarMunicipioSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_SUCCESS,
		payload,
	};
}
export function cadastrarMunicipioFailure(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_FAILURE,
		payload: null,
	};
}
export function cadastrarMunicipioClear(): ReducerActionRotaSP {
	return {
		type: Types.CADASTRAR_MUNICIPIO_CLEAR,
		payload: null,
	};
}
