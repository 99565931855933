import { ReducerActionRotaSP } from 'store/modules/types';

import { Types } from './types';

export function pesquisaMunicipiosRequest(data: string): ReducerActionRotaSP {
	return {
		type: Types.PESQUISA_MUNICIPIOS_REQUEST,
		payload: data,
	};
}
export function pesquisaMunicipiosSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.PESQUISA_MUNICIPIOS_SUCCESS,
		payload,
	};
}
export function pesquisaMunicipiosFailure(): ReducerActionRotaSP {
	return {
		type: Types.PESQUISA_MUNICIPIOS_FAILURE,
		payload: null,
	};
}
export function pesquisaMunicipiosClear(): ReducerActionRotaSP {
	return {
		type: Types.PESQUISA_MUNICIPIOS_CLEAR,
		payload: null,
	};
}
