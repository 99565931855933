import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, CadastrarMunicipioRequest } from './types';

// ACTIONS
import {
	cadastrarMunicipioSuccess,
	cadastrarMunicipioFailure,
} from './actions';

function* cadastrarMunicipioRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: CadastrarMunicipioRequest } = request;

	let body = { ...payload };

	body = cleanEmpty(body) as CadastrarMunicipioRequest;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_SGU_API,
		`municipios`,
		body,
		undefined,
		undefined,
		undefined,
	);

	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarMunicipioSuccess(response));
	} else {
		yield put(cadastrarMunicipioFailure());
	}
}

export default all([
	takeLatest(Types.CADASTRAR_MUNICIPIO_REQUEST, cadastrarMunicipioRequest),
]);
