import React from 'react';

import { Row, Col } from 'antd';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import TextArea from 'components/Common/Form/Input/TextArea';

// FORM
import { IFormInclusaoObsSegundaVia } from 'pages/iirgd/RgFicDigital/InclusaoObservacoes/Formularios/form';

// STYLED
import { ConsultarProcesso } from 'store/modules/api/rfb/consultarProcesso/types';
import { IconContainer, IconCheck, TextCheck } from '../styled';
import { Label, Value } from '../../../styled';

interface Props {
	data: IFormInclusaoObsSegundaVia;
}

export const SegundaVia: React.FC<Props> = ({ data }) => {
	const { cadastroRg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { consultarProcesso } = useSelector(
		(state: ApplicationState) => state.api.rfb,
	);

	const situacaoCin = (codigoSituacao: ConsultarProcesso[]) => {
		let situacao = false;
		codigoSituacao.forEach(item => {
			if (item.codigoSituacao === 3) {
				situacao = true;
			}
		});

		return situacao;
	};

	return (
		<>
			{cadastroRg.form.principal?.doador && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={20}>
						<TextCheck>Doador de Órgãos e Tecidos</TextCheck>
					</Col>
				</Row>
			)}

			{data.declaracaoPobreza && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={20}>
						<TextCheck>
							Declaro ser pobre, conforme a lei 7.115 de 29/08/1983
						</TextCheck>
					</Col>
				</Row>
			)}

			{data.isento2Via062 && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={20}>
						<TextCheck>
							Isento da taxa de 2ª via conforme Portaria 062/2001
						</TextCheck>
					</Col>
				</Row>
			)}

			{cadastroRg.dare &&
				(cadastroRg.dare.status === 0 || cadastroRg.dare.status) &&
				!data.declaracaoPobreza &&
				!data.isento2Via10952 &&
				!data.isento2Via062 && (
					<>
						<Row gutter={[0, 10]}>
							<Col span={4}>
								<IconContainer>
									<IconCheck />
								</IconContainer>
							</Col>
							<Col span={20}>
								<TextCheck>
									{cadastroRg.dare.status === 1 ||
									cadastroRg.dare.status === 2 ||
									cadastroRg.dare.status === 6 ||
									cadastroRg.dare.status !== 0
										? 'Taxa de segunda via paga.'
										: 'Taxa de segunda via a ser paga.'}
								</TextCheck>
							</Col>
						</Row>
					</>
				)}

			{data.solicitarBloqueio && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={20}>
						<TextCheck>
							Perdeu Carteira de Identidade e solicita seu bloqueio
						</TextCheck>
					</Col>
				</Row>
			)}

			{cadastroRg.dare &&
				cadastroRg.form.principal &&
				cadastroRg.form.principal.entrega !== 'P' &&
				cadastroRg.form.principal?.entrega !== 'K' && (
					<>
						<Row gutter={[0, 10]}>
							<Col span={4}>
								<IconContainer>
									<IconCheck />
								</IconContainer>
							</Col>
							<Col span={20}>
								<TextCheck>
									{cadastroRg.dare.status === 1 ||
									cadastroRg.dare.status === 2 ||
									cadastroRg.dare.status === 6
										? 'Taxa de correio paga.'
										: 'Taxa de correio a ser paga.'}
								</TextCheck>
							</Col>
						</Row>
					</>
				)}

			{data.isento2Via10952 && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={12}>
						<TextCheck>
							Isento da taxa de 2ª via conforme lei estadual 10.952 de
							07/11/2001
						</TextCheck>
					</Col>

					{data.ctpsObs && (
						<>
							<Col span={3}>
								<Label>CTPS:</Label>
							</Col>
							<Col span={5}>
								<Value>{`${data.ctpsObs} - ${data.srObs} ${data.estObs}`}</Value>
							</Col>
						</>
					)}
				</Row>
			)}

			{data.termoTutela && (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={12}>
						<TextCheck>Termo de Guarda/Tutela/Conselho</TextCheck>
					</Col>

					<Col span={3}>
						<Label>Proc.:</Label>
					</Col>
					<Col span={5}>
						<Value>{`${data.tutelaProc}/${data.tutelaAno} (${data.tutelaData})`}</Value>
					</Col>
				</Row>
			)}

			{data.termoRecolhimentoRg && (
				<>
					<Row gutter={[0, 10]}>
						<Col span={4}>
							<IconContainer>
								<IconCheck />
							</IconContainer>
						</Col>
						<Col span={20}>
							<TextCheck>
								Está ciente que a Carteira de Identidade abaixo será recolhido e
								inutilizada:
							</TextCheck>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={4}>
							<Label>Expedido em:</Label>
						</Col>
						<Col span={16}>
							<Value>{data.rgRecolhidoEmissao}</Value>
						</Col>
					</Row>

					<Row gutter={[0, 10]}>
						<Col span={4}>
							<Label>Setor Identificador:</Label>
						</Col>
						<Col span={2}>
							<Value>{data.rgRecolhidoIdentific}</Value>
						</Col>

						<Col span={4}>
							<Label>Recolhido por:</Label>
						</Col>
						<Col span={6}>
							<Value>{data.rgRecolhidoPor}</Value>
						</Col>
					</Row>
				</>
			)}

			{cadastroRg.form.principal?.dni === '10977' &&
			!situacaoCin(consultarProcesso.data || []) &&
			data.isencaoPrimeiraViaCin ? (
				<Row gutter={[0, 10]}>
					<Col span={4}>
						<IconContainer>
							<IconCheck />
						</IconContainer>
					</Col>
					<Col span={20}>
						<TextCheck>Isenção da Taxa da CIN</TextCheck>
					</Col>
				</Row>
			) : (
				''
			)}

			<Row gutter={[0, 10]}>
				<Col span={4}>
					<Label>Observações:</Label>
				</Col>
				<Col span={20}>
					<TextArea name="observacao" value={data.observacao} disabled />
				</Col>
			</Row>
		</>
	);
};
