// TYPES
import { IConfirmacaoAtendimento } from 'pages/DetranCnh/components/pages/ConfirmacaoAtendimento/form';
import { FormCnhEstrangeiro } from 'pages/DetranCnh/components/pages/PreCadastroEstrangeiro/StatusCondutor/form';
import { ConsultaCondutorResponse } from 'store/modules/api/detranCnh/condutor/consultaCondutor/types';
import {
	GerarRenachIntegrado,
	GerarRenachIntegradoRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/gerarRenachIntegrado/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { ILoginUnicoState, IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { IConfrontarDigitaisCnhState } from 'store/modules/api/utils/confrontarDigitaisCnh/types';
import { ConsultarDigitaisState } from 'store/modules/api/utils/consultarDigitais/types';
import {
	enumFormUseUrlStatusCondutor,
	enumServicesCnhWithAccents,
} from 'utils/constants/types';
import { ConsultaEPortalSegundaViaData } from 'store/modules/api/detranCnh/segundaVia/consultaEPortalSegundaVia/types';
import { IResponseConsultaCnhDefinitiva } from 'store/modules/api/detranCnh/cnhDefinitiva/consultaCnhDefinitiva/types';
import { AlterarCadastro } from 'store/modules/api/detranCnh/renachIntegrado/alterarCadastro/types';
import { PreCadastroCidadao } from 'store/modules/api/sguService/preCadastroCidadao/types';
import { CapturarBiometriaState } from 'store/modules/api/biometria/capturarBiometria/types';
import { EnderecosData } from 'store/modules/api/utils/enderecos/types';
import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';

// UTILS
import {
	getIdMotivoBiometricRead,
	getObservationPromotePassword,
	getObservationSelectedFieldsAlterarRenach,
	getObservationSelectedFieldsGerarRenachIntegrado,
	getObservationSelectedFieldsGerarRenachSegundaVia,
	getResponseDetailsEditedRenach,
	getResponseDetailsRenachIntegrado,
} from './functions';
import { getBiometricsProcessMessages } from '../biometrics';

const incluirRequestPayloadSelectedFieldsAndGeneratedRenach = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	dataGerarRenachIntegrado: GerarRenachIntegradoRequest,
) => {
	const processDescription =
		enumFormUseUrlStatusCondutor.filter(
			item =>
				Number(item.idTypeProcess) ===
				Number(dataGerarRenachIntegrado.candidato.tipoProcesso),
		)[0].descriptionWithSpaceAndAccents || '';

	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: dataGerarRenachIntegrado.candidato.cpf || '', // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 2, // Órgão DETRAN-CNH
		cpf: user.cpf, // cpf do atendente
		descricaoServico: processDescription,
		idMotivo: 16,
		observacao: '',
	};

	return payload;
};

// SGU CONFRONTO
export const treatRequestSguStatisticsBiometricRead = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	dataGerarRenach: GerarRenachIntegradoRequest,
	confrontoDigitaisCnh: IConfrontarDigitaisCnhState,
	consultarDigitais: ConsultarDigitaisState,
	biometricReadService: CapturarBiometriaState,
	dataModalAtendimento?: IConfirmacaoAtendimento,
	dataModalProcurador?: boolean | string,
): IncluirRequest => {
	const idMotivoValue = getIdMotivoBiometricRead(
		confrontoDigitaisCnh,
		consultarDigitais,
		biometricReadService,
		dataGerarRenach,
		dataModalAtendimento,
		dataModalProcurador,
	);

	const getInfoPayload = incluirRequestPayloadSelectedFieldsAndGeneratedRenach(
		user,
		dadosAtendimento,
		dataGerarRenach,
	);

	const usedFingersInBiometricRead = (confrontoDigitaisCnh?.dedoSorteio &&
		Array.isArray(confrontoDigitaisCnh.dedoSorteio) &&
		confrontoDigitaisCnh.dedoSorteio.length > 0 &&
		confrontoDigitaisCnh.dedoSorteio?.map(dedos => dedos.sigla)) || [''];

	const { messageAlert, processMessages } = ((!dataModalProcurador ||
		dataModalProcurador === 'N') &&
		getBiometricsProcessMessages(
			dataGerarRenach.gerarRenachBase?.usoFormularioEnum || '',
			consultarDigitais,
			confrontoDigitaisCnh,
			biometricReadService,
		)) || { messageAlert: '', processMessages: [] };

	const payload: IncluirRequest = {
		...getInfoPayload,
		idMotivo: Number(idMotivoValue),
		observacao: `${
			(idMotivoValue === '6' &&
				`${dataModalAtendimento?.justificativaAssinatura} - `) ||
			(idMotivoValue === '9' &&
				`${dataModalAtendimento?.justificativaFoto} - `) ||
			''
		}
			${(messageAlert.length > 0 && `${messageAlert} - `) || ''} ${
			(processMessages.length > 0 &&
				processMessages?.join(' - ')?.toString()) ||
			''
		}`,
		totalizador: idMotivoValue === '0' ? 'S' : 'N',
		flagConfronto: idMotivoValue === '0' ? 'S' : 'N',
		descricaoDedosEnviados:
			idMotivoValue === '0' ? usedFingersInBiometricRead.toString() : '',
	};

	return payload;
};

// SGU PROMOCAO DE SENHA - GRAVAR
export const treatRequestSguStatisticsPromotePassword = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	preCadastroCidadaoResponse: PreCadastroCidadao,
	dataGerarRenach: GerarRenachIntegradoRequest,
): IncluirRequest => {
	const getInfoPayload = incluirRequestPayloadSelectedFieldsAndGeneratedRenach(
		user,
		dadosAtendimento,
		dataGerarRenach,
	);

	const payload: IncluirRequest = {
		...getInfoPayload,
		descricaoServico: 'PROMOVER_SENHA',
		observacao: getObservationPromotePassword(
			dadosAtendimento,
			dataGerarRenach,
			preCadastroCidadaoResponse,
		),
	};

	return payload;
};

// SGU CAMPOS SELECIONADOS OU RENACH GERADO
export const treatRequestSguStatisticsGerarRenachIntegrado = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	dataGerarRenach: GerarRenachIntegradoRequest,
	typeStatistic: 'SELECTED_FIELDS' | 'GENERATED_RENACH',
	generatedRenachResponse?: GerarRenachIntegrado,
): IncluirRequest => {
	const getInfoPayload = incluirRequestPayloadSelectedFieldsAndGeneratedRenach(
		user,
		dadosAtendimento,
		dataGerarRenach,
	);

	const payload: IncluirRequest = {
		...getInfoPayload,
		observacao:
			(typeStatistic === 'SELECTED_FIELDS' &&
				getObservationSelectedFieldsGerarRenachIntegrado(dataGerarRenach)) ||
			(typeStatistic === 'GENERATED_RENACH' &&
				generatedRenachResponse &&
				getResponseDetailsRenachIntegrado(generatedRenachResponse)) ||
			'',
	};

	return payload;
};

// SGU CAMPOS SELECIONADOS OU RENACH GERADO
export const treatRequestSguStatisticsGerarRenachSegundaVia = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	responsePesquisaSegundaVia: ConsultaEPortalSegundaViaData,
	dataGerarRenachIntegrado: GerarRenachIntegradoRequest,
	typeStatistic: 'SELECTED_FIELDS' | 'GENERATED_RENACH',
	generatedRenachResponse?: GerarRenachIntegrado,
): IncluirRequest => {
	const getInfoPayload = incluirRequestPayloadSelectedFieldsAndGeneratedRenach(
		user,
		dadosAtendimento,
		dataGerarRenachIntegrado,
	);

	const payload: IncluirRequest = {
		...getInfoPayload,
		observacao:
			(typeStatistic === 'SELECTED_FIELDS' &&
				getObservationSelectedFieldsGerarRenachSegundaVia(
					responsePesquisaSegundaVia,
					dataGerarRenachIntegrado,
				)) ||
			(typeStatistic === 'GENERATED_RENACH' &&
				generatedRenachResponse &&
				getResponseDetailsRenachIntegrado(generatedRenachResponse)) ||
			'',
	};
	return payload;
};

// SGU CAMPOS SELECIONADOS OU RENACH GERADO
export const treatRequestSguStatisticsGerarRenachCnhDefinitiva = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	responsePesquisaCnhDefinitiva: IResponseConsultaCnhDefinitiva,
	dataGerarRenachIntegrado: GerarRenachIntegradoRequest,
	typeStatistic: 'SELECTED_FIELDS' | 'GENERATED_RENACH',
	generatedRenachResponse?: GerarRenachIntegrado,
): IncluirRequest => {
	const getInfoPayload = incluirRequestPayloadSelectedFieldsAndGeneratedRenach(
		user,
		dadosAtendimento,
		dataGerarRenachIntegrado,
	);

	const payload: IncluirRequest = {
		...getInfoPayload,
		observacao:
			(typeStatistic === 'SELECTED_FIELDS' &&
				getObservationSelectedFieldsGerarRenachSegundaVia(
					responsePesquisaCnhDefinitiva,
					dataGerarRenachIntegrado,
				)) ||
			(typeStatistic === 'GENERATED_RENACH' &&
				generatedRenachResponse &&
				getResponseDetailsRenachIntegrado(generatedRenachResponse)) ||
			'',
	};
	return payload;
};

// SGU CAMPOS SELECIONADOS OU RENACH GERADO
export const treatRequestSguStatisticsAlterarRenachGerado = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	dataAlterarRenach: FormCnhEstrangeiro,
	dataConsultarDadosCidadao: ConsultaCondutorResponse,
	typeStatistic: 'SELECTED_FIELDS' | 'EDITED_RENACH',
	editedRenachResponse?: AlterarCadastro,
): IncluirRequest => {
	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: dataAlterarRenach?.cpf || '', // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (user.posto && Number(user.posto)) || 0,
		idOrgao: 2, // Órgão DETRAN-CNH
		cpf: user.cpf, // cpf do atendente
		descricaoServico: 'Alterar RENACH',
		idMotivo: 16,
		observacao:
			(typeStatistic === 'SELECTED_FIELDS' &&
				getObservationSelectedFieldsAlterarRenach(
					dataAlterarRenach,
					dataConsultarDadosCidadao,
				)) ||
			(typeStatistic === 'EDITED_RENACH' &&
				editedRenachResponse &&
				getResponseDetailsEditedRenach(
					dataConsultarDadosCidadao,
					editedRenachResponse,
				)) ||
			'',
	};
	return payload;
};

export const treatRequestSguChangeAddress = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	cpfProccess: string,
	dataCorporativeCEP: EnderecosData[] | null,
	messageProccess: string,
	cepUsedInProccess: string,
): IncluirRequest => {
	const getInfoPayload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: cpfProccess || '', // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 2, // Órgão DETRAN-CNH
		cpf: user.cpf, // cpf do atendente
		descricaoServico: enumServicesCnhWithAccents.ALTERAR_ENDERECO,
		idMotivo: 10,
		observacao: '',
	};

	const payload: IncluirRequest = {
		...getInfoPayload,
		observacao: `${messageProccess} - CPF: ${cpfProccess}, CEP Utilizado: ${cepUsedInProccess}, Retorno do serviço de CEP Corporativo: ${
			(dataCorporativeCEP &&
				`numeroIBGE: ${dataCorporativeCEP[0].numeroIBGE}, codigoMunicipio: ${dataCorporativeCEP[0].codigoMunicipio}, codigoBairro: ${dataCorporativeCEP[0].codigoBairro}, codigoLocalRel: ${dataCorporativeCEP[0].codigoLocalRel}`) ||
			'Serviço sem dados de retorno'
		}`,
	};

	return payload;
};

export const treatRequestSguCorporativeZipCode = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	messageProccess: string,
	cepUsedInProccess: string,
	dataCorporativeCEP: string | null,
): IncluirRequest => {
	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: '', // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: user.idOrgao,
		cpf: user.cpf, // cpf do atendente
		descricaoServico: 'Log - CEP Corporativo Integrador',
		idMotivo: 10,
		observacao: `${messageProccess} - CEP Utilizado: ${cepUsedInProccess}, Retorno do serviço de CEP Corporativo: ${
			dataCorporativeCEP || 'Serviço sem dados de retorno'
		}`,
	};

	return payload;
};

export const treatRequestSguEducacaoResponseEvents = (
	loginUnico: ILoginUnicoState,
	dadosAtendimento: AtendimentoState,
	cpfAtendimento: string,
	typeResponseEvent: boolean,
	eventType: string,
	eventTypeId: number,
	eventTypeDescription: string,
): IncluirRequest => {
	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: cpfAtendimento, // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.salvarAtendimento.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (loginUnico.login.user?.posto && Number(loginUnico.login.user.posto)) || 0,
		idOrgao: 6,
		cpf: loginUnico.login.user.cpf, // cpf do atendente
		descricaoServico:  `EDUCAÇÃO - ${eventTypeDescription}`,
		idMotivo: eventTypeId,
		observacao: typeResponseEvent ? `${eventType} - Contablizado com sucesso` :  `${eventType} - Contablizado com erro`,
	};

	return payload;
};

