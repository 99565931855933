import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, putApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';
import { ReducerAction } from 'store/modules/types';
import { cleanEmpty } from 'utils/genericFunctions';
import { Types, AtualizarMunicipioIbgeRequest } from './types';
import {
	atualizarMunicipioIbgeSuccess,
	atualizarMunicipioIbgeFailure,
} from './actions';

function* atualizarMunicipioIbgeRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: AtualizarMunicipioIbgeRequest } = request;

	let body = { ...payload };

	body = cleanEmpty(body) as AtualizarMunicipioIbgeRequest;

	const response: ApiResponse = yield call(
		putApi,
		token || '',
		PATH_SGU_API,
		`municipios-ibge/${payload.idNaturalidade}`,
		body,
	);

	if (response.status === 200) {
		yield put(atualizarMunicipioIbgeSuccess(response));
	} else {
		yield put(atualizarMunicipioIbgeFailure());
	}
}

export default all([
	takeLatest(
		Types.ATUALIZAR_MUNICIPIO_IBGE_REQUEST,
		atualizarMunicipioIbgeRequest,
	),
]);
