import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, deleteApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ExcluirMunicipioIbgeRequest } from './types';

// ACTIONS
import {
	excluirMunicipioIbgeSuccess,
	excluirMunicipioIbgeFailure,
} from './actions';

function* excluirMunicipioIbgeRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: ExcluirMunicipioIbgeRequest } = request;

	const response: ApiResponse = yield call(
		deleteApi,
		token || '',
		PATH_SGU_API,
		`municipios-ibge/${payload.idNaturalidade}`,
	);

	if (response.status === 200) {
		yield put(excluirMunicipioIbgeSuccess(response));
	} else {
		yield put(excluirMunicipioIbgeFailure());
	}
}

export default all([
	takeLatest(Types.EXCLUIR_MUNICIPIO_IBGE_REQUEST, excluirMunicipioIbgeRequest),
]);
