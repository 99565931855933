import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_SGU_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ConsultarMunicipioRequest, Types } from './types';

// ACTIONS
import {
	pesquisaMunicipiosSuccess,
	pesquisaMunicipiosFailure,
} from './actions';

function* pesquisaMunicipiosRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: ConsultarMunicipioRequest } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_SGU_API,
		`municipios/${payload.id}`,
		payload,
	);

	if (response.status === 200) {
		yield put(pesquisaMunicipiosSuccess(response));
	} else {
		yield put(pesquisaMunicipiosFailure());
	}
}

export default all([
	takeLatest(Types.PESQUISA_MUNICIPIOS_REQUEST, pesquisaMunicipiosRequest),
]);
