import {
	ROUTE_BOLSA_POVO,
	ROUTE_CDHU,
	ROUTE_DETRAN_CNH,
	ROUTE_DETRAN_CRV,
	ROUTE_IIRGD,
	ROUTE_MINISTERIO_PUBLICO,
	ROUTE_POUPATEMPO,
	ROUTE_PREFEITURA,
	ROUTE_PROCON,
	ROUTE_SECRETARIA_EDUCACAO,
	ROUTE_SEDS,
	ROUTE_SGU,
} from 'routes/paths';

// TYPES
import { ClickedOptionHasRecoveryService } from 'store/modules/user/acoesUso/types';

export const menuPaths = [
	ROUTE_DETRAN_CNH,
	ROUTE_IIRGD,
	ROUTE_DETRAN_CRV,
	ROUTE_SECRETARIA_EDUCACAO,
	ROUTE_SEDS,
	ROUTE_POUPATEMPO,
	ROUTE_PREFEITURA,
	ROUTE_SGU,
	ROUTE_BOLSA_POVO,
	ROUTE_PROCON,
	ROUTE_MINISTERIO_PUBLICO,
	ROUTE_CDHU,
];

export const verifyRecoverServiceWasClicked = (
	pathList: string[],
	recoverService: ClickedOptionHasRecoveryService | undefined,
	currentUrl: string,
) => {
	if (!pathList) {
		return false;
	}

	const menuPathSelected = (pathList[0] && pathList[0]) || '';
	const selectedServicePath = (pathList[1] && pathList[1]) || '';

	if (
		(menuPathSelected &&
			selectedServicePath &&
			recoverService?.clickedOptionPath === selectedServicePath) ||
		menuPathSelected === currentUrl ||
		currentUrl === '/' ||
		menuPaths.includes(currentUrl)
	) {
		return true;
	}

	return false;
};
