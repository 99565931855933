import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { toSelect } from 'utils/genericFunctions';
import { Types, ListaMotivoJustificativa } from './types';

export const INITIAL_STATE: ListaMotivoJustificativa = {
	status: 0,
	data: [],
};

const motivoJustificativaEnum = [
	{
		codigo: 'AGENDADO_POR_OUTRO',
		motivo: 'AGENDAMENTO EFETUADO POR OUTRO CIDADÃO',
	},
	{
		codigo: 'CIDADAO_NAO_CORRESPONDENTE',
		motivo: 'CIDADÃO RECUPERADO NÃO CORRESPONDE AO ATENDIMENTO',
	},
	{
		codigo: 'CIDADAO_SEM_SENHA',
		motivo: 'CIDADÃO ATENDIDO SEM SENHA',
	},
	{
		codigo: 'OUTROS',
		motivo: 'OUTROS',
	},
];

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ListaMotivoJustificativa {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LISTA_MOTIVO_JUSTIFICATIVA_REQUEST: {
				draft.status = 200;
				const select = toSelect(motivoJustificativaEnum, 'motivo', 'codigo');
				draft.data = select;
				break;
			}

			case Types.LISTA_MOTIVO_JUSTIFICATIVA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
