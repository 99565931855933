/* eslint-disable radix */
import {
	AtualizarPortalSolicitacaoRequest,
	Phone,
} from 'store/modules/api/mp/atualizarSolicitacaoPortal/types';
import { IRequestEnviarSolicitacaoMP } from 'store/modules/api/mp/enviarSolicitacaoMP/types';
import { RecuperarAtendimento } from 'store/modules/api/sgu/atendimento/recuperar/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import {
	ILogin,
	IParametrosLocalidade,
} from 'store/modules/api/sgu/loginUnico/types';

import {
	formatDateISOWithTimeZone,
	limparMascara,
	unformatDateTime,
	validacaoData,
	verificarMaioridade,
} from 'utils/genericFunctions';
import { validateEmail } from 'utils/yupValidations';
import * as Yup from 'yup';

export interface SguData {
	recuperarAtendimento: RecuperarAtendimento;
	salvarAtendimento: SalvarAtendimento;
	login: ILogin;
	user: {
		cpf: string;
		nome: string;
		orgaos: any;
		login?: boolean;
		parametrosSistema: IParametrosLocalidade[];
		perfil: any;
	};
}
export interface ICadastroSolicitante {
	idSolicitacao?: number;
	destinatarioSolicitacao?: string;
	// rg
	RGempty?: boolean;
	// Tipo solicitante
	tipoSolicitante: number;

	// Termos
	aceiteTermo: boolean;
	aceiteTermoDeclaracaoPobreza: boolean;
	aceiteAcrescNomePai: boolean;
	aceiteAcrescimoNome: boolean;
	informacoesAdicionais: string;
	protocolo?: string;
	status?: string;
	statusCode?: number | null;

	mensagem?: string;
	dataCriacao: string;
	dataModificacao: string;
	idCidadao: string;
	idAtendimento: string;
	emailContato: string;

	anexos: Anexos[];
	anexosTipo?: string;

	// Filho ou Filha
	filho: {
		nome: string;
		dtNascimento: string;
		cpf: string;
		email?: string;
		sexo: string;
		endereco?: {
			logradouro: string;
			numero: string;
			complemEndereco: string;
			bairro: string;
			cep: string;
			cidade: string;
			uf: string;
		};
		rg?: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones?: {
			fixo: string;
			celular: string;
			comercial: string;
		};
		genitor: {
			nome: string;
			sexo: string;
			cpf?: string;
			email?: string;
			dtNascimento?: string;
			nomeMae?: string;
			rg?: {
				numero: string;
				digito: string;
				uf: string;
			};
			telefones?: {
				fixo: string;
				celular: string;
				comercial: string;
			};
			endereco?: {
				logradouro: string;
				complemEndereco: string;
				numero: string;
				bairro: string;
				cep: string;
				cidade: string;
				uf: string;
			};
			outrasInformacoes: string;
		};
	};
	responsavel?: {
		nome?: string;
		dtNascimento?: string;
		email?: string;
		sexo?: string;
		cpf?: string;
		rg?: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones?: {
			fixo: string;
			celular: string;
			comercial: string;
		};
		endereco?: {
			logradouro: string;
			complemEndereco: string;
			numero: string;
			bairro: string;
			cep: string;
			cidade: string;
			uf: string;
		};
	};
}

interface Anexos {
	nome: string;
	tipo: string;
	conteudo: string;
}

export const initialValues: ICadastroSolicitante = {
	mensagem: '',
	dataCriacao: '',
	dataModificacao: '',
	idCidadao: '',
	idAtendimento: '',
	emailContato: '',
	idSolicitacao: 0,
	RGempty: false,
	tipoSolicitante: 0,
	anexos: [],
	anexosTipo: '',
	filho: {
		nome: '',
		dtNascimento: '',
		cpf: '',
		sexo: '',
		email: '',
		endereco: {
			logradouro: '',
			numero: '',
			complemEndereco: '',
			bairro: '',
			cep: '',
			cidade: '',
			uf: '',
		},
		rg: {
			numero: '',
			digito: '',
			uf: '',
		},
		telefones: {
			fixo: '',
			celular: '',
			comercial: '',
		},
		genitor: {
			nome: '',
			sexo: '',
			dtNascimento: '',
			outrasInformacoes: '',
			nomeMae: '',
			rg: {
				numero: '',
				digito: '',
				uf: '',
			},
			telefones: {
				fixo: '',
				celular: '',
				comercial: '',
			},
			endereco: {
				logradouro: '',
				complemEndereco: '',
				numero: '',
				bairro: '',
				cep: '',
				cidade: '',
				uf: '',
			},
		},
	},
	responsavel: {
		nome: '',
		dtNascimento: '',
		sexo: '',
		cpf: '',
		rg: {
			numero: '',
			digito: '',
			uf: '',
		},
		telefones: {
			fixo: '',
			celular: '',
			comercial: '',
		},
		endereco: {
			logradouro: '',
			complemEndereco: '',
			numero: '',
			bairro: '',
			cep: '',
			cidade: '',
			uf: '',
		},
	},
	aceiteTermo: false,
	aceiteAcrescNomePai: false,
	aceiteTermoDeclaracaoPobreza: false,
	aceiteAcrescimoNome: false,
	informacoesAdicionais: '',
};

export const schema = [
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado : Nome Completo.`,
			),
			email: Yup.string().required(`Campo Obrigatório: Interessado : E-mail.`)
			.test({
				name: 'emailValidation',
				exclusive: true,
				message: 'Campo E-mail: Interessado: E-mail inválido.',
				test: value => validateEmail(value),
			}),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Interessado : Data de Nascimento.`)
				// eslint-disable-next-line func-names
				.test(
					'test-name',
					() => `Essa solicitação é apenas para Filhos maiores de Idade.`,
					(value: any) =>
						value === '' || !validacaoData(value)
							? true
							: verificarMaioridade(value),
				)
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Interessado : CPF.`),
			}),
			endereco: Yup.object().shape({
				logradouro: Yup.string().required(
					`Campo Obrigatório: Interessado : Logradouro.`,
				),
				numero: Yup.string().required(
					`Campo Obrigatório: Interessado : Número.`,
				),
				bairro: Yup.string().required(
					`Campo Obrigatório: Interessado : Bairro.`,
				),
				cep: Yup.string().required(`Campo Obrigatório: Interessado : CEP.`),
				cidade: Yup.string().required(
					`Campo Obrigatório: Interessado : Cidade.`,
				),
				uf: Yup.string().required(
					`Campo Obrigatório: Interessado : UF Endereço.`,
				),
			}),
			telefone: Yup.string().when('telefones', {
				is: telefones =>
					telefones.fixo === '' &&
					telefones.celular === '' &&
					telefones.comercial === '',
				then: Yup.string().required(
					`Necessário informar pelo menos 1 telefone para contato do solicitante.`,
				),
			}),
			genitor: Yup.object().shape({
				nome: Yup.string().required(
					`Campo Obrigatório: Genitor : Nome Completo.`,
				),
				sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
			}),
		}),
		termo1: Yup.string().when('aceiteTermo', {
			is: aceiteTermo => aceiteTermo === false,
			then: Yup.string().required(`Campo Obrigatório: Interessado : Termo 1.`),
		}),
		anexos: Yup.array().min(
			1,
			`Necessário incluir pelo menos 1 anexo na solicitação.`,
		),
	}),
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado : Nome Completo.`,
			),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Interessado : Data de Nascimento.`)
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Interessado : CPF.`),
			}),
			genitor: Yup.object().shape({
				nome: Yup.string().required(
					`Campo Obrigatório: Genitor : Nome Completo.`,
				),
				sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
			}),
		}),
		anexos: Yup.array().min(
			1,
			`Necessário incluir pelo menos 1 anexo na solicitação.`,
		),
		responsavel: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Responsável : Nome Completo.`,
			),
			email: Yup.string().required(`Campo Obrigatório: Responsável : E-mail.`),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Responsável : Data de Nascimento.`)
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Responsável : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Responsável : CPF.`),
			}),
			endereco: Yup.object().shape({
				logradouro: Yup.string().required(
					`Campo Obrigatório: Genitor : Logradouro.`,
				),
				numero: Yup.string().required(
					`Campo Obrigatório: Responsável : Número.`,
				),
				bairro: Yup.string().required(
					`Campo Obrigatório: Responsável : Bairro.`,
				),
				cep: Yup.string().required(`Campo Obrigatório: Responsável : CEP.`),
				cidade: Yup.string().required(
					`Campo Obrigatório: Responsável : Cidade.`,
				),
				uf: Yup.string().required(`Campo Obrigatório: Responsável : UF.`),
			}),
			telefone: Yup.string().when('telefones', {
				is: telefones =>
					telefones.fixo === '' &&
					telefones.celular === '' &&
					telefones.comercial === '',
				then: Yup.string().required(
					`Necessário informar pelo menos 1 telefone para contato do solicitante.`,
				),
			}),
		}),
		termo1: Yup.string().when('aceiteTermo', {
			is: aceiteTermo => aceiteTermo === false,
			then: Yup.string().required(`Campo Obrigatório: Responsável : Termo 1.`),
		}),
	}),
	Yup.object<ICadastroSolicitante>().shape({
		filho: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Interessado : Nome Completo.`,
			),
			dtNascimento: Yup.string()
				.required(`Campo Obrigatório: Interessado : Data de Nascimento.`)
				// eslint-disable-next-line func-names
				.test(
					'test-name',
					value =>
						`Data de Nascimento: '${value.value}' não é uma data válida.`,
					(value: any) => (value === '' ? true : validacaoData(value)),
				),
			sexo: Yup.string().required(`Campo Obrigatório: Interessado : Sexo.`),
			cpf: Yup.string().when(['dtNascimento'], {
				is: dtNascimento =>
					verificarMaioridade(dtNascimento) === true || dtNascimento === '',
				then: Yup.string().required(`Campo Obrigatório: Interessado : CPF.`),
			}),
			genitor: Yup.object().shape({
				nome: Yup.string().required(
					`Campo Obrigatório: Genitor : Nome Completo.`,
				),
				cpf: Yup.string().required(`Campo Obrigatório: Genitor : CPF.`),
				dtNascimento: Yup.string().required(
					`Campo Obrigatório: Genitor : Data de Nascimento.`,
				),
				sexo: Yup.string().required(`Campo Obrigatório: Genitor : Sexo.`),
				email: Yup.string().required(`Campo Obrigatório: Genitor : E-mail.`),
				endereco: Yup.object().shape({
					logradouro: Yup.string().required(
						`Campo Obrigatório: Genitor : Logradouro.`,
					),
					numero: Yup.string().required(`Campo Obrigatório: Genitor : Número.`),
					bairro: Yup.string().required(`Campo Obrigatório: Genitor : Bairro.`),
					cep: Yup.string().required(`Campo Obrigatório: Genitor : CEP.`),
					cidade: Yup.string().required(`Campo Obrigatório: Genitor : Cidade.`),
					uf: Yup.string().required(`Campo Obrigatório: Genitor : UF.`),
				}),
				telefone: Yup.string().when('telefones', {
					is: telefones =>
						telefones.fixo === '' &&
						telefones.celular === '' &&
						telefones.comercial === '',
					then: Yup.string().required(
						`Necessário informar pelo menos 1 telefone para contato do solicitante.`,
					),
				}),
			}),
		}),
		termo1: Yup.string().when('aceiteTermo', {
			is: aceiteTermo => aceiteTermo === false,
			then: Yup.string().required(
				`Termo de Aceite deve ser informado(a) para a solicitação.`,
			),
		}),
		responsavel: Yup.object().shape({
			nome: Yup.string().required(
				`Campo Obrigatório: Responsável : Nome Completo.`,
			),
			sexo: Yup.string().required(`Campo Obrigatório: Responsável : Sexo.`),
		}),
		anexos: Yup.array().min(
			1,
			`Necessário incluir pelo menos 1 anexo na solicitação.`,
		),
	}),
];

function hasValue(obj: any, key: string) {
	if (!obj) return false;
	return (
		Object.prototype.hasOwnProperty.call(obj, key) &&
		obj[key] !== '00000000-0000-0000-0000-000000000000'
	);
}

export const getIdData = (data: SguData) => {
	const { salvarAtendimento, recuperarAtendimento } = data;
	const idData: any = {
		idCidadao: '',
		idAtendimento: '',
	};
	if (hasValue(recuperarAtendimento.data, 'idCidadao')) {
		idData.idCidadao = recuperarAtendimento.data?.idCidadao;
	}
	if (hasValue(recuperarAtendimento.data, 'idAtendimento')) {
		idData.idAtendimento = recuperarAtendimento.data?.idAtendimento;
	}
	if (
		idData.idCidadao === '' &&
		hasValue(salvarAtendimento.data, 'idCidadao')
	) {
		idData.idCidadao = salvarAtendimento.data?.idCidadao;
	}
	if (
		idData.idAtendimento === '' &&
		hasValue(salvarAtendimento.data, 'idAtendimento')
	) {
		idData.idAtendimento = salvarAtendimento.data?.idAtendimento;
	}
	return idData;
};

const formatListPhone = (
	phoneList: ICadastroSolicitante['filho']['telefones'],
): Phone[] | [] => {
	const treatedPhoneList: Phone[] = [];

	if (phoneList) {
		Object.keys(phoneList).forEach(item => {
			if (item === 'fixo' && phoneList.fixo) {
				treatedPhoneList.push({
					ddd: limparMascara(phoneList.fixo).substr(0, 2),
					numero: limparMascara(phoneList.fixo).substr(2, 8),
					tipo: 1,
				});
			} else if (item === 'celular' && phoneList.celular) {
				treatedPhoneList.push({
					ddd: limparMascara(phoneList.celular).substr(0, 2),
					numero: limparMascara(phoneList.celular).substr(2, 8),
					tipo: 3,
				});
			} else if (item === 'comercial' && phoneList.comercial) {
				treatedPhoneList.push({
					ddd: limparMascara(phoneList.comercial).substr(0, 2),
					numero: limparMascara(phoneList.comercial).substr(2, 8),
					tipo: 2,
				});
			}
		});
	}

	return treatedPhoneList;
};

const treatRequestDefault = (
	values: ICadastroSolicitante,
	solicitante: number,
	sguData: SguData,
	solicitacaoId?: string,
) => {
	const { cpfAtendente, idCidadao, idAtendimento } = getIdData(sguData);
	const { nomePosto, posto } = sguData.login.user;

	const data = {
		filho: {
			nome: values.filho.nome || '',
			sexo: values.filho.sexo || '',
			cpf: (values.filho.cpf && limparMascara(values.filho.cpf)) || '',
			dataNascimento:
				(values.filho?.dtNascimento &&
					formatDateISOWithTimeZone(
						new Date(unformatDateTime(values.filho.dtNascimento)),
					)) ||
				'',
			email: values.filho.email || '',
			endereco: {
				bairro: values.filho.endereco?.bairro || '',
				cep: values.filho.endereco?.cep || '',
				cidade: values.filho.endereco?.cidade || '',
				complementoEndereco: values.filho.endereco?.complemEndereco || '',
				logradouro: values.filho.endereco?.logradouro || '',
				numero: values.filho.endereco?.numero || '',
				uf: values.filho.endereco?.uf || '',
			},
			rg: {
				numero: values.filho.rg?.numero || '',
				digito: values.filho.rg?.digito || '',
				uf: values.filho.rg?.uf || '',
			},
			telefones: values.filho.telefones
				? formatListPhone(values.filho.telefones)
				: [],
			genitor: {
				nome: values.filho.genitor.nome || '',
				sexo: values.filho.genitor.sexo || '',
				dataNascimento:
					(values.filho?.genitor?.dtNascimento &&
						formatDateISOWithTimeZone(
							new Date(unformatDateTime(values.filho.genitor.dtNascimento)),
						)) ||
					'',
				cpf:
					(values.filho.genitor.cpf &&
						limparMascara(values.filho.genitor.cpf)) ||
					'',
				email: values.filho.genitor.email || '',
				endereco: {
					bairro: values.filho.genitor.endereco?.bairro || '',
					cep: values.filho.genitor.endereco?.cep || '',
					cidade: values.filho.genitor.endereco?.cidade || '',
					complementoEndereco:
						values.filho.genitor.endereco?.complemEndereco || '',
					logradouro: values.filho.genitor.endereco?.logradouro || '',
					numero: values.filho.genitor.endereco?.numero || '',
					uf: values.filho.genitor.endereco?.uf || '',
				},
				rg: {
					numero: values.filho.genitor.rg?.numero || '',
					digito: values.filho.genitor.rg?.digito || '',
					uf: values.filho.genitor.rg?.uf || '',
				},
				telefones: values.filho.genitor.telefones
					? formatListPhone(values.filho.genitor.telefones)
					: [],
				nomeMae: values.filho.genitor.nomeMae || '',
				cidadeNascimento: '',
				ufNascimento: '',
				outrasInformacoes: values.filho.genitor.outrasInformacoes || '',
			},
		},
		destinatarioSolicitacao: solicitante ? solicitante.toString() : '',
		aceiteTermo: values.aceiteTermo,
		aceiteTermoDeclaracaoPobreza: values.aceiteTermoDeclaracaoPobreza,
		aceiteAcrescNomePai: values.aceiteAcrescNomePai,
		idAtendente: cpfAtendente || '',
		dataCriacao: values.dataCriacao,
		dataModificacao: formatDateISOWithTimeZone(new Date()) || '',
		status: values.status || '',
		origem: 'P',
		protocolo: '',
		idCidadao: values.idCidadao
			? values.idCidadao
			: idCidadao || '00000000-0000-0000-0000-000000000000',
		idAtendimento: idAtendimento || '',
		emailContato: '',
		idPosto: Number(posto) || 0,
		nomePosto,
		idSolicitacao:
			solicitacaoId ||
			(values.status && values?.idSolicitacao?.toString()) ||
			'',
	};

	return data;
};

export const treatRequestAtualizarSolicitacao = (
	values: ICadastroSolicitante,
	solicitante: number,
	sguData: SguData,
	solicitacaoId?: string,
): AtualizarPortalSolicitacaoRequest => {
	const defaultData = treatRequestDefault(
		values,
		solicitante,
		sguData,
		solicitacaoId,
	);

	if (solicitante === 2 || solicitante === 3) {
		return {
			...defaultData,
			responsavel: {
				nome: values.responsavel?.nome || '',
				dataNascimento:
					(values.responsavel?.dtNascimento &&
						formatDateISOWithTimeZone(
							new Date(unformatDateTime(values.responsavel.dtNascimento)),
						)) ||
					'',
				cpf:
					(values.responsavel?.cpf && limparMascara(values.responsavel.cpf)) ||
					'',
				email: values.responsavel?.email || '',
				sexo: values.responsavel?.sexo || '',
				endereco: {
					bairro: values.responsavel?.endereco?.bairro || '',
					cep: values.responsavel?.endereco?.cep || '',
					cidade: values.responsavel?.endereco?.cidade || '',
					complementoEndereco:
						values.responsavel?.endereco?.complemEndereco || '',
					logradouro: values.responsavel?.endereco?.logradouro || '',
					numero: values.responsavel?.endereco?.numero || '',
					uf: values.responsavel?.endereco?.uf || '',
				},
				rg: {
					numero: values.responsavel?.rg?.numero || '',
					digito: values.responsavel?.rg?.digito || '',
					uf: values.responsavel?.rg?.uf || '',
				},
				telefones: values.responsavel?.telefones
					? formatListPhone(values.responsavel.telefones)
					: [],
			},
		};
	}

	return defaultData;
};

export const treatSendMPRequest = (
	values: ICadastroSolicitante,
	solicitante: number,
	sguData: SguData,
	solicitacaoId?: string,
): IRequestEnviarSolicitacaoMP => {
	const defaultData = treatRequestDefault(
		values,
		solicitante,
		sguData,
		solicitacaoId,
	);

	const { genitor } = defaultData.filho;
	const { filho } = defaultData;

	const data: IRequestEnviarSolicitacaoMP = {
		interessado: {
			cpf: filho.cpf,
			dataNascimento: filho.dataNascimento,
			email: filho.email,
			endereco: {
				...filho.endereco,
			},
			nome: filho.nome,
			rg: (filho.rg.numero && `${filho.rg.numero}-${filho.rg.digito}`) || '',
			ufRg: filho.rg.uf,
			sexo: filho.sexo || '',
			telefones: filho.telefones,
		},
		genitor: {
			cpf: genitor.cpf,
			dataNascimento: genitor.dataNascimento,
			email: genitor.email,
			endereco: genitor.endereco,
			localNascimento: '',
			nome: genitor.nome,
			nomeMae: genitor.nomeMae,
			rg:
				(genitor.rg.numero && `${genitor.rg.numero}-${genitor.rg.digito}`) ||
				'',
			ufRg: genitor.rg.uf,
			sexo: genitor.sexo || '',
			telefones: genitor.telefones,
		},
		data:
			defaultData.dataCriacao || formatDateISOWithTimeZone(new Date()) || '',
		postoPoupatempo: defaultData.nomePosto,
		informacoesAdicionais: '',
		anexos: values.anexos,
	};

	if (solicitante === 2 || solicitante === 3) {
		return {
			...data,
			responsavel: {
				nome: values.responsavel?.nome || '',
				dataNascimento:
					(values.responsavel?.dtNascimento &&
						formatDateISOWithTimeZone(
							new Date(unformatDateTime(values.responsavel.dtNascimento)),
						)) ||
					'',
				cpf:
					(values.responsavel?.cpf && limparMascara(values.responsavel.cpf)) ||
					'',
				email: values.responsavel?.email || '',
				sexo: values.responsavel?.sexo || '',
				endereco: {
					bairro: values.responsavel?.endereco?.bairro || '',
					cep: values.responsavel?.endereco?.cep || '',
					cidade: values.responsavel?.endereco?.cidade || '',
					complementoEndereco:
						values.responsavel?.endereco?.complemEndereco || '',
					logradouro: values.responsavel?.endereco?.logradouro || '',
					numero: values.responsavel?.endereco?.numero || '',
					uf: values.responsavel?.endereco?.uf || '',
				},
				rg:
					(values.responsavel?.rg?.numero &&
						`${values.responsavel.rg.numero}-${values.responsavel.rg.digito}`) ||
					'',
				ufRg: values.responsavel?.rg?.uf || '',
				telefones: values.responsavel?.telefones
					? formatListPhone(values.responsavel.telefones)
					: [],
			},
		};
	}

	return data;
};
