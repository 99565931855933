import { all } from 'redux-saga/effects';

import api from './api/combineSaga';
import atendimento from './atendimento/saga';
import sgu from './sgu/saga';
import user from './user/combineSaga';

export default function* rootSaga(): Generator {
	return yield all([api, atendimento, sgu, user]);
}
