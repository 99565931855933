import React, { useEffect } from 'react';

import { Row, Col } from 'antd';

// COMPONENTS
import Section from 'components/Common/Section';

// UTILS
import ButtonImage from 'components/Common/ButtonImage';
import { useHistory } from 'react-router-dom';
import ColorBox from 'components/Common/ColorBox';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { GerarRenachRequest } from 'store/modules/api/detranCnh/sockets/gerarRenach/types';
import { threatValuesEndSessionEventRequest } from 'utils/functions/threatEventsRequest';
import { eventoTerminoSessaoRequest } from 'store/modules/api/detranCnh/evento/terminoSessao/actions';

interface IProps {
	requestData: GerarRenachRequest;
}

const CadastroGeradoCnhDigital: React.FC<IProps> = ({ requestData }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { detailsStartSection } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.evento.eventoInicioSessao,
	);

	useEffect(() => {
		const valuesEndSessionEvent = threatValuesEndSessionEventRequest(
			atendimento,
			loginUnico,
			requestData.usoFormularioEnum || '',
			detailsStartSection?.dataInicio || '',
		);

		dispatch(eventoTerminoSessaoRequest(valuesEndSessionEvent));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<>
			<Section title="Status do Condutor">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<h3 style={{ textTransform: 'uppercase' }}>{requestData.nome}</h3>
					</Col>
				</Row>

				<Row align="middle" justify="center">
					<Col span={15}>
						<ColorBox minHeight={200} bgColor="verde" title>
							<Row>
								<Col span={24}>
									<p>CPF: {requestData.cpf}</p>
								</Col>
							</Row>

							<Row align="middle" justify="center">
								<Col>
									<ButtonImage
										type="button"
										src="finalizar-processo"
										onClick={() => history.push('/detran-cnh')}
									/>
								</Col>
							</Row>
						</ColorBox>
					</Col>
				</Row>
			</Section>

			<Row justify="end" align="middle">
				<Col>
					<ButtonImage
						type="button"
						src="outro-servico-detran"
						onClick={() => history.push('/detran-cnh')}
					/>
				</Col>
			</Row>
		</>
	);
};

export default CadastroGeradoCnhDigital;
