import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ConsultarMunicipiosIbgeOdRequest } from './types';

export function consultarMunicipiosIbgeRequest(
	token: string,
	data: ConsultarMunicipiosIbgeOdRequest,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_REQUEST,
		token,
		payload: {
			page: data.current,
			size: data.limite,
			idNaturalidade: data.codigoNaturalidade,
			idIbge: data.codigoIbge,
			uf: data.siglaEstado,
			descricao: data.nome,
		},
	};
}
export function consultarMunicipiosIbgeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_SUCCESS,
		payload,
	};
}
export function consultarMunicipiosIbgeFailure(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_FAILURE,
		payload: null,
	};
}
export function consultarMunicipiosIbgeClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_MUNICIPIOS_IBGE_CLEAR,
		payload: null,
	};
}
