import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import { ENVIRONMENT } from 'services/path';

import rootReducer from './modules/rootReducer';

import rootSaga from './modules/rootSaga';

// TYPES
import { ApiState } from './modules/api/types';
import { AppState } from './modules/app/types';

import { AtendimentoState } from './modules/atendimento/types';
import { SGUState } from './modules/sgu/types';
import { UserState } from './modules/user/types';

export interface ApplicationState {
	api: ApiState;
	app: AppState;
	atendimento: AtendimentoState;
	sgu: SGUState;
	user: UserState;
}

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['api', 'app', 'sgu', 'user'],
};

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, rootReducer);

if (ENVIRONMENT === `LOCAL`) {
	middlewares.push(logger);
}

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
