import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, AtualizarMunicipioIbgeRequest } from './types';

export function atualizarMunicipioIbgeRequest(
	token: string,
	data: AtualizarMunicipioIbgeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_REQUEST,
		token,
		payload: data,
	};
}
export function atualizarMunicipioIbgeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_SUCCESS,
		payload,
	};
}
export function atualizarMunicipioIbgeFailure(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_FAILURE,
		payload: null,
	};
}
export function atualizarMunicipioIbgeClear(): ReducerActionRotaSP {
	return {
		type: Types.ATUALIZAR_MUNICIPIO_IBGE_CLEAR,
		payload: null,
	};
}
