// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types } from './types';

export function listaMotivoJustificativaRequest(): ReducerAction {
	return {
		type: Types.LISTA_MOTIVO_JUSTIFICATIVA_REQUEST,
		payload: null,
	};
}

export function listaMotivoJustificativaClear(): ReducerAction {
	return {
		type: Types.LISTA_MOTIVO_JUSTIFICATIVA_CLEAR,
		payload: null,
	};
}
