export enum Types {
	ACOES_USO_REQUEST = '@user/ACOES_USO_REQUEST',
	ACOES_USO_SUCCESS = '@user/ACOES_USO_SUCCESS',
	ACOES_USO_CLEAR = '@user/ACOES_USO_CLEAR',
}

export interface AcoesUsoState {
	data: AcoesUsoData | null;
}

export interface AcoesUsoRequest {
	clickedOptionPath: string;
	clickedOptionHasRecoveryService?: ClickedOptionHasRecoveryService;
}

export interface AcoesUsoData {
	clickedOptionPathList: string[];
	clickedOptionHasRecoveryService?: ClickedOptionHasRecoveryService;
}

export interface ClickedOptionHasRecoveryService {
	clickedOptionHasRecoveryService: boolean;
	clickedOptionPath: string;
}
