import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { simularEfetuarAcordoRequest } from 'store/modules/api/cdhu/simularEfetuarAcordo/actions';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import InputMask from 'components/Common/Form/Input/InputMask';
import Money from 'components/Common/Form/Fields/Money';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { formatStringNumber } from 'utils/numberFunctions';

// FORM
import { unformatDate } from 'utils/genericFunctions';
import { IFormPropostaAcordo, initialValues, schema } from './form';

const Proposta: React.FC = () => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [hasFirstRequest, setFirstRequest] = useState<boolean>(false);

	const { consultarMutuario, gerarOcorrencia, simularEfetuarAcordo } =
		useSelector((state: ApplicationState) => state.api.cdhu);
	
	useEffect(() => {
		if (
			simularEfetuarAcordo.status === 200 &&
			simularEfetuarAcordo.data &&
			simularEfetuarAcordo.request &&
			hasFirstRequest &&
			hasLoaded
		) {
			if (
				simularEfetuarAcordo.request.novoPrazo?.toString() !==
				simularEfetuarAcordo.data.qtdParcelasAcordo
			) {
				dispatch(
					simularEfetuarAcordoRequest(token, simularEfetuarAcordo.request),
				);
				setFirstRequest(false);
			}
		}
	}, [
		dispatch,
		simularEfetuarAcordo.data,
		simularEfetuarAcordo.request,
		simularEfetuarAcordo.status,
		token,
		hasFirstRequest,
		hasLoaded,
	]);

	useEffect(() => {
		if (simularEfetuarAcordo.data) {
			const { valorParcelaInicial, qtdParcelasAcordo } =
				simularEfetuarAcordo.data;

			initialValues.entradaAcordoPadrao =
				valorParcelaInicial && formatStringNumber(valorParcelaInicial);
			initialValues.prazoAcordoPadrao = qtdParcelasAcordo;

			setHasLoaded(true);
		}
	}, [simularEfetuarAcordo.data, dispatch]);

	const handleTipoSimulacao = useCallback(
		(values: IFormPropostaAcordo): number => {
			const { novaDataPagamento, novoValorEntrada, novoPrazoAcordo } = values;
			// const { novoPrazoAcordo } = values;

			if (novaDataPagamento && novoValorEntrada && novoPrazoAcordo) {
				return 3;
			}

			if (novaDataPagamento && novoValorEntrada) {
				return 4;
			}

			if (novoPrazoAcordo) {
				return 2;
			}

			return 0;
		},
		[],
	);

	const handleValidate = useCallback((values: IFormPropostaAcordo) => {
		return getValidationsErrors(values, schema);
	}, []);

	const handleSubmit = useCallback(
		(values: IFormPropostaAcordo) => {
			const tipoSimulacao = handleTipoSimulacao(values);

			if (
				gerarOcorrencia.data &&
				consultarMutuario.data &&
				simularEfetuarAcordo.data &&
				tipoSimulacao !== 0
			) {
				const { ocorrenciaId } = gerarOcorrencia.data;
				const { numeroContrato } = consultarMutuario.data;
				const { acordoId } = simularEfetuarAcordo.data;

				const formattedDataPrestacaoInicial = unformatDate(
					values.novaDataPagamento,
				);

				if (tipoSimulacao === 2) {
					dispatch(
						simularEfetuarAcordoRequest(token, {
							servicoEnum: 'SIMULAR_ACORDO',
							acordoId,
							ocorrenciaId,
							contratoCDHU: Number(numeroContrato),
							novoPrazo: Number(values.novoPrazoAcordo),
							tipoSimulacao,
						}),
					);
				} else if (tipoSimulacao === 3) {
					dispatch(
						simularEfetuarAcordoRequest(token, {
							servicoEnum: 'SIMULAR_ACORDO',
							acordoId,
							ocorrenciaId,
							amortizacaoInicial: Number(values.novoValorEntrada),
							contratoCDHU: Number(numeroContrato),
							dataVencimentoBoleto: formattedDataPrestacaoInicial,
							novoPrazo: Number(values.novoPrazoAcordo),
							tipoSimulacao,
						}),
					);
				} else if (tipoSimulacao === 4) {
					dispatch(
						simularEfetuarAcordoRequest(token, {
							servicoEnum: 'SIMULAR_ACORDO',
							acordoId,
							ocorrenciaId,
							amortizacaoInicial: Number(values.novoValorEntrada),
							contratoCDHU: Number(numeroContrato),
							dataVencimentoBoleto: formattedDataPrestacaoInicial,
							tipoSimulacao,
						}),
					);
				}
				setFirstRequest(true);
			}
		},
		[
			consultarMutuario.data,
			dispatch,
			gerarOcorrencia.data,
			handleTipoSimulacao,
			simularEfetuarAcordo.data,
			token,
		],
	);

	return (
		<>
			{hasLoaded && (
				<FormBox title="Proposta de acordo" fullHeight>
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={handleValidate}
						onSubmit={handleSubmit}
					>
						{formik => (
							<>
								<Form autoComplete="off">
									<Row gutter={[0, 5]}>
										<Col span={24}>
											<Field
												as={InputMask}
												name="novaDataPagamento"
												title="Defina a data de pagamento do acordo"
												titleSize={280}
												mask="99/99/9999"
												error={
													!!formik.errors.novaDataPagamento ||
													hasError(formik.errors, 'required')
												}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 30]}>
										<Col />
									</Row>

									<Row gutter={[0, 5]}>
										<Col span={24}>
											<Money
												name="novoValorEntrada"
												title="Digite o valor da parcela de entrada do acordo (R$)"
												titleSize={280}
												defaultValue={initialValues.novoValorEntrada}
												formik={formik}
												error={
													hasError(formik.errors, 'required') ||
													hasError(formik.errors, 'valorEntrada')
												}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 10]}>
										<Col span={24} style={{ textAlign: 'center' }}>
											<strong>e/ou</strong>
										</Col>
									</Row>

									<Row gutter={[0, 5]}>
										<Col span={24}>
											<Field
												as={InputMask}
												name="novoPrazoAcordo"
												title="Digite o novo prazo de acordo (Quantidade)"
												titleSize={280}
												mask="999"
												maskChar=""
												error={
													hasError(formik.errors, 'required') ||
													hasError(formik.errors, 'prazoAcordo')
												}
											/>
										</Col>
									</Row>

									<Row gutter={[0, 15]}>
										<Col />
									</Row>

									<Row justify="center">
										<Col>
											<ButtonImage type="submit" src="simular" />
										</Col>
									</Row>
								</Form>
							</>
						)}
					</Formik>
				</FormBox>
			)}
		</>
	);
};

export default Proposta;
