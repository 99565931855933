import { useDispatch } from 'react-redux';

// ROUTING
import { useHistory, useLocation } from 'react-router-dom';

// REDUX
import React, { useCallback } from 'react';
import {
	redirecionamentoAtendimento,
	redirecionamentoAtendimentoClear,
} from 'store/modules/atendimento/actions';
import { registraAcoesUsoRequest } from 'store/modules/user/acoesUso/actions';

// STYLED
import { Container, Button } from './styled';

interface Props {
	id: string;
	name: string;
	path: string;
	descricaoServico?: string;
	recuperaAtendimento?: boolean;
	dadosAgendamentoIIRGD?: boolean;
}

const Service: React.FC<Props> = ({
	id,
	name,
	path,
	descricaoServico,
	recuperaAtendimento = true,
	dadosAgendamentoIIRGD,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const handleClick = useCallback(() => {
		dispatch(redirecionamentoAtendimentoClear());
		dispatch(
			registraAcoesUsoRequest({
				clickedOptionPath:
					path.charAt(0) === '/' ? path : `${location.pathname}/${path}`,
				clickedOptionHasRecoveryService: {
					clickedOptionPath:
						path.charAt(0) === '/' ? path : `${location.pathname}/${path}`,
					clickedOptionHasRecoveryService: recuperaAtendimento,
				},
			}),
		);

		if (dadosAgendamentoIIRGD && !recuperaAtendimento) {
			dispatch(
				redirecionamentoAtendimento({
					route: path.charAt(0) === '/' ? path : `${location.pathname}/${path}`,
					descricaoServico,
				}),
			);
			history.push(`${location.pathname}/dados-agendamento`);
			return;
		}

		if (!recuperaAtendimento) {
			history.push(
				path.charAt(0) === '/' ? path : `${location.pathname}/${path}`,
			);
			return;
		}

		dispatch(
			redirecionamentoAtendimento({
				route: path.charAt(0) === '/' ? path : `${location.pathname}/${path}`,
				descricaoServico,
			}),
		);
		history.push(`${location.pathname}/recuperar-atendimento`);
	}, [
		dispatch,
		recuperaAtendimento,
		dadosAgendamentoIIRGD,
		path,
		location.pathname,
		descricaoServico,
		history,
	]);

	return (
		<Container>
			<Button id={id} onClick={handleClick}>
				{name}
			</Button>
		</Container>
	);
};

export default Service;
