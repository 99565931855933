import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, AcoesUsoState } from './types';

export const INITIAL_STATE: AcoesUsoState = {
	data: null,
};

export default function acoesUso(
	state = INITIAL_STATE,
	action: ReducerAction,
): AcoesUsoState {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ACOES_USO_REQUEST: {
				if (draft.data?.clickedOptionPathList?.length) {
					draft.data = {
						clickedOptionPathList: [
							...draft.data.clickedOptionPathList,
							action.payload.clickedOptionPath,
						],
						clickedOptionHasRecoveryService:
							action.payload.clickedOptionHasRecoveryService,
					};
				} else {
					draft.data = {
						clickedOptionPathList: [action.payload.clickedOptionPath],
						clickedOptionHasRecoveryService:
							action.payload.clickedOptionHasRecoveryService,
					};
				}

				break;
			}

			case Types.ACOES_USO_CLEAR: {
				draft.data = INITIAL_STATE.data;
				break;
			}
			default:
		}
	});
}
