import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Row, Col, List, Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';

// COMPONENTS
import Section from 'components/Common/Section';
import Button from 'components/Common/Button';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// UTILS
import { formatDate, patternFormat } from 'utils/genericFunctions';
import { isFilledValue } from 'utils/stringFunctions';
import {
	consultarCpfCinClear,
	consultarCpfCinRequest,
} from 'store/modules/api/iirgd/integrador/consultarCin/actions';
import {
	cadastrarCinClear,
	cadastrarCinRequest,
} from 'store/modules/api/rfb/cadastrarCin/actions';
import { CadastrarCinRequest } from 'store/modules/api/rfb/cadastrarCin/types';

import { ConsultaCpf } from 'store/modules/api/iirgd/pesquisaCicp/types';
import {
	consultarFotosRgClear,
	consultarFotosRgRequest,
} from 'store/modules/api/utils/consultarFotosRg/actions';
import { consultarMunicipiosIbgeRequest } from 'store/modules/api/sgu/administrar/municipios/ibge/consultarMunicipiosIbge/actions';
import { emitirCinTreatPayloadRequest, IBGECodes } from '../Formulario/form';

const ResultadoCpf: React.FC = () => {
	const dispatch = useDispatch();

	const { consultarMunicipiosIbge }: any = useSelector(
		(state: ApplicationState) => state.api.sgu.administrar.municipios.ibge,
	);

	const consultarCpf = useSelector(
		(state: ApplicationState) => state.api.iirgd.pesquisaCicp,
	);

	const { consultaCIN } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { consultarFotosRg } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { consultarCin } = useSelector(
		(state: ApplicationState) => state.api.iirgd.integrador,
	);

	const { consultaPesquisaCirg } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { token } = useContext<IAuthContext>(AuthContext);

	const locale = {
		emptyText: 'Nenhum registro encontrado',
	};

	const [cpfItem, setCpfItem] = useState<ConsultaCpf | null>(null);
	const [IBGECountiesCodes, setIBGECountiesCodes] = useState<IBGECodes>({
		codigoMunicipioResidencia: null,
		codigoNaturalidade: null,
		codigoMunicipioEmissao: null,
	});

	const getIBGECode = useCallback(
		(key: string, countyNaturalityCode: string) => {
			const countyNaturalityCodeTreated =
				Number(countyNaturalityCode.split('-')[0]) || 0;

			if (
				key &&
				consultarMunicipiosIbge.data?.length &&
				countyNaturalityCodeTreated
			) {
				const countyIBGECode =
					consultarMunicipiosIbge.data?.filter(
						(item: { idNaturalidade: number }) =>
							item.idNaturalidade === countyNaturalityCodeTreated,
					)[0]?.idIbge || 0;

				setIBGECountiesCodes(oldValues => ({
					...oldValues,
					[key]: countyIBGECode,
				}));
			}
		},
		[consultarMunicipiosIbge.data],
	);

	const handleSubmitForm = useCallback(
		item => {
			getIBGECode('codigoMunicipioResidencia', item.codigoNaturalidade);
			setCpfItem({ ...item, cpf: item.cpf.replace('-', '') });

			Modal.confirm({
				content: <h5>Deseja emitir o RG de acordo com o Decreto 10.977?</h5>,
				style: {
					animationDuration: '0s',
					marginTop: '25vh',
				},
				okText: 'Sim',
				cancelText: 'Não',
				width: 455,
				onOk: () => {
					dispatch(consultarFotosRgRequest(item.numeroRg.replace('-', '')));
				},
				onCancel: () => {},
			});
		},
		[dispatch, getIBGECode],
	);

	useEffect(() => {
		dispatch(consultarCpfCinClear());
		dispatch(cadastrarCinClear());
		dispatch(consultarFotosRgClear());
		dispatch(consultarMunicipiosIbgeRequest(token, {}));
	}, [dispatch, token]);

	useEffect(() => {
		if (consultarFotosRg.status === 200 && cpfItem) {
			dispatch(consultarCpfCinRequest(token, cpfItem.cpf));
		}
	}, [dispatch, cpfItem, consultaCIN.status, consultarFotosRg.status, token]);

	useEffect(() => {
		if (consultarCin.status === 200 && cpfItem && consultarFotosRg.data) {
			const formData: CadastrarCinRequest = emitirCinTreatPayloadRequest(
				consultaCIN,
				cpfItem,
				login.user.cpf,
				IBGECountiesCodes,
				consultaPesquisaCirg,
				consultarFotosRg.data.origem,
			);

			dispatch(cadastrarCinRequest(formData));
		}
	}, [
		dispatch,
		cpfItem,
		consultarFotosRg.data,
		consultarCin.status,
		consultaCIN,
		login.user.cpf,
		consultarCpf,
		IBGECountiesCodes,
		consultaPesquisaCirg,
	]);

	return (
		<>
			{consultarCpf.data && (
				<Row>
					<Col span={24}>
						<Section title="Resultado da Consulta">
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<List
										itemLayout="horizontal"
										size="large"
										locale={locale}
										dataSource={consultarCpf.data}
										split={false}
										pagination={{
											showSizeChanger: true,
											pageSize: 10,
											pageSizeOptions: ['10', '20'],
										}}
										renderItem={item => (
											<>
												<Section key={item.cpf} style={{ marginTop: '5px' }}>
													<Row>
														<Col span={11}>
															<p>
																<strong>RG: </strong>
																{isFilledValue(item.numeroRg)}
															</p>
															<p>
																<strong>CPF: </strong>
																{isFilledValue(
																	patternFormat(item.cpf, `###.###.###-##`),
																)}
															</p>
															<p>
																<strong>Nome: </strong>
																{isFilledValue(item.nome)}
															</p>
															<p>
																<strong>Nome da Mãe: </strong>
																{isFilledValue(item.nomeMae)}
															</p>
															<p>
																<strong>Nome do Pai: </strong>
																{isFilledValue(item.nomePai)}
															</p>
														</Col>

														<Col span={8}>
															<p>
																<strong>Data de Nascimento: </strong>
																{isFilledValue(formatDate(item.dataNascimento))}
															</p>

															<p>
																<strong>Naturalidade: </strong>
																{isFilledValue(item.municipioNaturalidade)}
															</p>
														</Col>

														<Col span={5}>
															{item.atendimentoCIN &&
																item.atendimentoCIN === 'N' && (
																	<b style={{ color: 'red' }}>
																		CPF não está apto para emissão do CIN
																	</b>
																)}
															{item.pidt &&
																item.pidt !==
																	`${login.user.idLocal}-${login.user.idLocalidade}` && (
																	<b style={{ color: 'red' }}>
																		CPF está em atendimento no posto:{' '}
																		{item.pidt}
																	</b>
																)}
															<Button
																disabled={
																	(!!item.atendimentoCIN &&
																		item.atendimentoCIN === 'N') ||
																	(!!item.pidt &&
																		item.pidt !==
																			`${login.user.idLocal}-${login.user.idLocalidade}`)
																}
																onClick={() => handleSubmitForm(item)}
															>
																Emitir CIN
															</Button>
														</Col>
													</Row>
												</Section>
											</>
										)}
									/>
								</Col>
							</Row>
						</Section>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ResultadoCpf;
