import { combineReducers } from 'redux';

// TYPES
import { Types } from './types';

// REDUCERS
import api from './api/combineReducers';
import app from './app/combineReducers';
import atendimento from './atendimento/reducer';
import sgu from './sgu/reducer';
import user from './user/combineReducers';

const allReducers = combineReducers({
	api,
	app,
	atendimento,
	sgu,
	user,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === Types.CLEAR_APP) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
