import { ReducerAction } from 'store/modules/types';

import { AcoesUsoData, AcoesUsoRequest, Types } from './types';

export function registraAcoesUsoRequest(data: AcoesUsoRequest): ReducerAction {
	return {
		type: Types.ACOES_USO_REQUEST,
		payload: data,
	};
}

export function registraAcoesUsoSuccess(data: AcoesUsoData): ReducerAction {
	return {
		type: Types.ACOES_USO_SUCCESS,
		payload: data,
	};
}

export function registraAcoesUsoClear(): ReducerAction {
	return {
		type: Types.ACOES_USO_CLEAR,
		payload: null,
	};
}
