import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import Select from 'components/Common/Form/Select';
import ButtonImageOrgao from 'components/Common/ButtonImageOrgao';
import { ApplicationState } from 'store';
import { consultaMainframe, limpaMainframe } from 'store/modules/sgu/actions';
import { OptionsMainframe } from 'store/modules/sgu/types';
import {
	logOffRequest,
	fixLogIn,
} from 'store/modules/api/sgu/loginUnico/actions';

import {
	logonRestClear,
	logonRestRequest,
} from 'store/modules/api/detranCnh/eportal/logonRest/actions';
import {
	logonClear,
	logonRequest,
} from 'store/modules/api/iirgd/logon/actions';
import { registraAcoesUsoRequest } from 'store/modules/user/acoesUso/actions';

import {
	ILogin,
	IParametrosLocalidade,
} from 'store/modules/api/sgu/loginUnico/types';

// import { getECpfTokenClear } from 'store/modules/api/eCpf/getToken/actions';
// import { getECpfValidationClear } from 'store/modules/api/eCpf/getECpfValidation/actions';

import { getValidationsErrorsMenu } from 'utils/getValidationsErrors';
import Input from 'components/Common/Form/Input/Input';
import LightButton from 'components/Common/Buttons/Light';
import {
	addNotifications,
	clearNotifications,
} from 'store/modules/app/notifications/actions';
import {
	salvaSenhaUsuarioClear,
	salvaSenhaUsuarioRequest,
} from 'store/modules/api/sguService/salvaSenhaUsuario/actions';
import { SalvaSenhaUsuarioResponse } from 'store/modules/api/sguService/salvaSenhaUsuario/types';
import {
	alteraSenhaClear,
	alteraSenhaRequest,
} from 'store/modules/api/iirgd/alteraSenha/actions';
import {
	alteraSenhaDetranClear,
	alteraSenhaDetranRequest,
} from 'store/modules/api/detranCnh/eportal/alteraSenhaDetran/actions';
import TooltipInfo from 'components/Common/Tooltips/Info';
import { CANAL_SISTEMA } from 'utils/constants';
import {
	novoInitialValuesMenuOrgaoFormData,
	NovoMenuOrgaoFormData,
	redirect,
	schema,
} from '../form';

import { Box, BoxContent, TooltipInput } from '../styled';

export interface ItemMenuOrgaos {
	menuInfo: MenuInfo;
	setStartECpf?: (v: boolean) => void;
	setCertificateVisible?: (v: boolean) => void;
	setCertificateValid?: (v: boolean) => void;
	certificateValid?: boolean;
}

export interface MenuInfo {
	id: string;
	localidades: Localidades[];
	mainframe: boolean;
	nome: string;
	select: Select[];
}

export interface Select {
	digito_posto: string;
	label: string;
	nomePosto: string;
	posto: number;
	value: number;
	parametrosLocalidade: IParametrosLocalidade[] | [];
}

export interface Localidades {
	codigo: number;
	digito: string;
	nome: string;
	id: number;
	parametrosLocalidade: IParametrosLocalidade[] | [];
}

export interface MainframeOptions {
	label: string;
	value: string;
	codigoLocalidade: number;
	senha: string;
}

// 1º PASSO: CHAMAR ALTERAR / SALVAR SENHA
// 2º PASSO: ATUALIZAR MAINFRAME PARA PEGAR SENHA CRIPTOGRAFADO
// 3º PASSO: QUANDO TIVER SALVO E ATUALIZADO O MAINFRAME, CHAMAR O LOGON

const MenuItem: React.FC<ItemMenuOrgaos> = ({
	menuInfo,
	// setStartECpf = () => {},
	// setCertificateVisible = () => {},
	setCertificateValid = () => {},
	certificateValid = false,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState<NovoMenuOrgaoFormData>(
		novoInitialValuesMenuOrgaoFormData,
	);
	const { idTokenData, logOut } = useContext<IAuthContext>(AuthContext);
	const [mainframeToUpdate, setMainframeToUpdate] = useState<boolean>(true);
	// CONTROLAR 1º PASSO ALTERAR / SALVAR SENHA
	const [isPasswordUpdated, setIsPasswordUpdated] = useState<boolean>(false);
	// CONTROLAR 2º PASSO QUANDO O MAINFRAME FOI ATUALIZADO APÓS ALTERAR/SALVAR SENHA
	const [mainframeUpdated, setMainframeUpdated] = useState<boolean>(false);

	const [newPassword, setNewPassword] =
		useState<SalvaSenhaUsuarioResponse | null>(null);

	const [mainframeOptions, setMainframeOptions] = useState<MainframeOptions[]>(
		[],
	);

	const mainframe = useSelector(
		(state: ApplicationState) => state.sgu.consultaMainframe,
	);
	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);
	const { loginUnico } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { enderecoIp } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);
	const { logonRest, alteraSenhaDetran } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.eportal,
	);
	const { logon, alteraSenha } = useSelector(
		(state: ApplicationState) => state.api.iirgd,
	);
	const { salvaSenhaUsuario } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { errors } = useSelector(
		(state: ApplicationState) => state.app.notifications,
	);

	// ATUALIZA OS VALORES INICIAIS
	const handleInitialValues = useCallback(() => {
		setFormData(prev => {
			return {
				...prev,
				idOrgao: menuInfo.id,
				idLocal:
					menuInfo.select.length === 1 && !menuInfo.mainframe
						? menuInfo.select[0].value
						: '',
				hasMainFrame: !!menuInfo.mainframe,
				posto: menuInfo.select.length === 1 ? menuInfo.select[0].posto : '',
				digito_posto:
					menuInfo.select.length === 1 ? menuInfo.select[0].digito_posto : '',
				nomePosto:
					menuInfo.select.length === 1 ? menuInfo.select[0].nomePosto : '',
			};
		});
	}, [menuInfo]);

	// FAZ A CONSULTA DO MAINFRAME
	const handleMainframe = useCallback(
		(codLocalidade: number | string, idOrgao: string | number) => {
			if (!codLocalidade || !idOrgao || (idOrgao && idOrgao === '')) {
				return;
			}
			if (
				idOrgao === 3 ||
				idOrgao === 4 ||
				idOrgao === 5 ||
				idOrgao === 6 ||
				idOrgao === 10 ||
				idOrgao === 12 ||
				idOrgao === 13
			) {
				// LISTA DE ORGÃOS QUE NÃO CHAMAM O MAINFRAME
				return;
			}

			setMainframeToUpdate(true);

			dispatch(
				consultaMainframe({
					codigoLocalidade: Number(codLocalidade),
					cpf: user.cpf,
					orgao: Number(idOrgao),
				}),
			);
		},
		[dispatch, user.cpf],
	);

	// CHAMA O MAINFRAME APOS ATUALIZACAO DA SENHA
	useEffect(() => {
		if (mainframe.status === 0 && isPasswordUpdated && formData.idOrgao) {
			handleMainframe(formData.idLocal, formData.idOrgao);
		}
	}, [
		formData.idLocal,
		formData.idOrgao,
		handleMainframe,
		isPasswordUpdated,
		mainframe.status,
	]);

	// CHAMA AO MONTAR O COMPONENTE A ATUALIZAÇÃO DOS VALORES INICIAIS
	useEffect(() => {
		handleInitialValues();
	}, [handleInitialValues]);

	// ATUALIZA OS VALORES DO MAINFRAME
	const handleMainframeUpdate = useCallback(
		(item: OptionsMainframe) => {
			setMainframeToUpdate(false);

			// ASSIM GARANTE QUE SÓ RODA AO SELECIONAR OS 2 ORGÃOS QUE POSSUEM MAINFRAME
			if (Number(menuInfo.id) !== 1 && Number(menuInfo.id) !== 2) {
				return;
			}

			// formatOptions => ORGANIZA PARA PREENCHER O SELECT DO MENU
			const formatOptions = {
				label: item.label,
				value: item.value,
				codigoLocalidade: item.codigoLocalidade
					? Number(item.codigoLocalidade)
					: 0,
				senha: item.senha ? item.senha : '',
			};

			// ATUALIZA SENHA DO MAINFRAME SELECIONADO
			if (formatOptions.value === formData.idMainframe) {
				setFormData(prev => {
					return {
						...prev,
						idMainframe: formatOptions.value,
						senhaMainframe: formatOptions.senha,
					};
				});
			}

			setMainframeOptions(oldFormatOptions => [
				...oldFormatOptions,
				formatOptions,
			]);

			if (isPasswordUpdated) setMainframeUpdated(true);
		},
		[formData.idMainframe, isPasswordUpdated, menuInfo.id],
	);

	useEffect(() => {
		if (
			menuInfo.mainframe &&
			mainframeToUpdate &&
			mainframe.status === 200 &&
			mainframe.options.length
		) {
			const mainframeSelected = mainframe.options.filter(
				(item: { orgao: number }) => item.orgao === Number(menuInfo?.id),
			);

			if (mainframeSelected[0]?.data) {
				mainframeSelected[0].data.map(item => {
					if (
						item.codigoLocalidade &&
						item.codigoLocalidade === mainframe.dataRequest?.codigoLocalidade
					) {
						handleMainframeUpdate(item);
					}
					return null;
				});
			}
		}
	}, [handleMainframeUpdate, mainframe, menuInfo, mainframeToUpdate]);

	// REDIRECT IIRGD
	useEffect(() => {
		if (
			logon.status === 200 &&
			menuInfo.id &&
			logon.mensagem !== 'NOVAPASSWORD'
		) {
			dispatch(limpaMainframe());
			history.push('/iirgd');
		}
	}, [history, logon.mensagem, logon.status, menuInfo.id, dispatch]);

	// REDIRECT DETRAN CNH
	useEffect(() => {
		if (
			logonRest.status === 200 &&
			menuInfo.id &&
			logonRest.mensagem !== 'NOVAPASSWORD'
		) {
			dispatch(limpaMainframe());
			history.push('/detran-cnh');
		}
	}, [history, logonRest.mensagem, logonRest.status, menuInfo.id, dispatch]);

	// APAGA AS SENHAS QUANDO PEDE NOVO PASSWORD
	useEffect(() => {
		if (
			(logonRest.status === 200 || logon.status === 200) &&
			menuInfo.id &&
			logon.mensagem === 'NOVAPASSWORD'
		) {
			setFormData(prev => {
				return {
					...prev,
					novaSenhaMainframe: '',
					confirmaNovaSenhaMainframe: '',
				};
			});
		}
	}, [logon.mensagem, logon.status, logonRest.status, menuInfo.id]);

	// LIMPA A SENHA EM CASO DE SENHA DE LOGON NÃO FUNCIONE
	useEffect(() => {
		if (
			(logon.status === 400 &&
				logon.mensagem.trim() === 'Codigo e/ou Password Invalido.') ||
			(logonRest.status === 400 &&
				logonRest.mensagem.trim() === 'Codigo e/ou Password Invalido.') ||
			(logonRest.status === 400 &&
				logonRest.mensagem.trim() !==
					'Password deve conter caracteres alfabetico') ||
			(logon.status === 400 &&
				logon.mensagem.trim() !== 'Password deve conter caracteres alfabetico')
		) {
			setFormData(prev => {
				return {
					...prev,
					novaSenhaMainframe: '',
					confirmaNovaSenhaMainframe: '',
				};
			});
		}
	}, [
		alteraSenha.status,
		alteraSenhaDetran.status,
		logon.mensagem,
		logon.status,
		logonRest.mensagem,
		logonRest.status,
	]);

	// ATUALIZA O LOGIN UNICO A CADA PASSO
	const getLoginData = useCallback(
		(values: NovoMenuOrgaoFormData): ILogin => {
			const dadosLoginUnico: Select[] = menuInfo.select.filter(item => {
				return item.value === values.idLocal;
			});

			const newLoginData: ILogin = {
				user: {
					cpf: user?.cpf || '',
					idOrgao: values?.idOrgao ? Number(values.idOrgao) : 0,
					idLocal: dadosLoginUnico[0]?.posto
						? Number(dadosLoginUnico[0].posto)
						: 0,
					idCanal: CANAL_SISTEMA.PRESENCIAL.ID,
					descCanal: CANAL_SISTEMA.PRESENCIAL.DESCRICAO,
					posto: dadosLoginUnico[0]?.posto?.toString() || '',
					digito_posto: dadosLoginUnico.length
						? dadosLoginUnico[0].digito_posto
						: '',
					ip: enderecoIp.data ?? '0:0:0:0:0:0:0:1',
					nomePosto: dadosLoginUnico.length ? dadosLoginUnico[0].nomePosto : '',
					parametrosLocalidade: dadosLoginUnico.length
						? dadosLoginUnico[0].parametrosLocalidade
						: [],
					idLocalidade: Number(values.idLocal) || 0,
				},
				mainframe: {
					idUsuario: values?.idMainframe || '',
					senhaMainFrame: values?.senhaMainframe || '',
				},
			};
			dispatch(fixLogIn(newLoginData));
			return newLoginData;
		},
		[dispatch, enderecoIp.data, menuInfo.select, user],
	);

	// FAZ A CHAMADA DOS SERVIÇOS DE LOGON
	const handleLogon = useCallback(
		(values: NovoMenuOrgaoFormData) => {
			if (values && values.idLocal) {
				getLoginData(values);
			}
			// LOGON CNH
			if (Number(values.idOrgao) === 2) {
				return dispatch(
					logonRestRequest({
						usuario: values.idMainframe.toString(),
						senha: values.senhaMainframe.toString(),
						enderecoIp: enderecoIp.data,
					}),
				);
			}
			// LOGON IIRGD
			return dispatch(
				logonRequest({
					usuario: values.idMainframe.toString(),
					senha: values.senhaMainframe.toString(),
				}),
			);
		},
		[dispatch, getLoginData, enderecoIp],
	);

	const handleSubmit = useCallback(
		async (values: NovoMenuOrgaoFormData) => {
			const url = redirect(values.idOrgao);
			const certificadoDigitalGov = idTokenData?.amr.includes('x509') || false;

			// CASO TENHA URL LIBERADA E FOR DETRAN CNH, COLOCAR CERTIFICADO DIGITAL
			if (
				Number(values.idOrgao) === 2

				// &&
				// window.location.href.indexOf('www.poupatempo4g.sp.gov.br/') !== -1
			) {
				if (url) {
					dispatch(
						registraAcoesUsoRequest({
							clickedOptionPath: url,
						}),
					);
				}
				if (!certificadoDigitalGov) {
					let extraParams = {};
					if (
						!(
							window.location.href.indexOf('www.poupatempo4g.sp.gov.br/') !== -1
						)
					) {
						extraParams = {
							cancelText: 'Seguir sem certificado',
							onCancel() {
								handleLogon(values);
							},
							cancelButtonProps: {
								ghost: true,
							},
						};
					}
					Modal.confirm({
						title: 'Necessário efetuar login com eCpf',
						content: (
							<div>
								<div style={{ marginTop: '30px' }}>
									Para ter acesso ao detran cnh é necessário ser feito login no
									Gov.br usando certificado digital, por favor faça o login
									novamente com o certificado.
								</div>
							</div>
						),
						...extraParams,
						onOk() {
							localStorage.clear();
							if (idTokenData) {
								logOut();
							}
							dispatch(logOffRequest());
						},
					});

					return null;
				}

				if (values && values.idLocal) {
					getLoginData(values);
				}
			}
			if (url) {
				dispatch(
					registraAcoesUsoRequest({
						clickedOptionPath: url,
					}),
				);
				// MONTA O OBJETO PARA ACESSO AO LOGIN UNICO
				if (values && values.idLocal) {
					getLoginData(values);
				}
				// VERIFICA SE O ORGÃO POSSUI O PASSO EXTRA DE LOGON
				// dispatch(fixLogIn(newLoginData));
				if (Number(values.idOrgao) === 2 || Number(values.idOrgao) === 1) {
					// CASO TENHA NECESSIDADE DE LOGON IRÁ GUARDAR NO ESTADO LOGINDATA PARA QUE POSSA ATUALIZA EM CASO DE SUCESSO
					return handleLogon(values);
				}
				history.push(url);
			} else {
				// ORGÃO NÃO LIBERADO PARA IR PARA PRODUÇÃO OU ID NÃO CADASTRADO
				Modal.info({
					title: 'Órgão em Desenvolvimento!',
					style: {
						animationDuration: '0s',
					},

					content: (
						<div>
							<div style={{ marginTop: '30px' }}>
								<strong>
									Obrigado por testar mais este órgão, mas no momento ele está
									em desenvolvimento.
								</strong>{' '}
							</div>
							<div style={{ marginTop: '15px' }}>
								Em breve mais um órgão para ajudar no dia a dia do Poupatempo.
							</div>
						</div>
					),
					onOk() {},
				});
			}
			return null;
		},
		[
			dispatch,
			getLoginData,
			handleLogon,
			history,
			// setCertificateVisible,
			// setStartECpf,
			idTokenData,
			logOut,
		],
	);

	const handleNovaSenha = useCallback(
		(values: NovoMenuOrgaoFormData, tipo?: 'confirmacao') => {
			if (errors.length) {
				dispatch(clearNotifications());
			}
			if (values && values.idLocal) {
				getLoginData(values);
			}
			if (tipo === 'confirmacao') {
				if (values.novaSenhaMainframe !== values.confirmaNovaSenhaMainframe) {
					return addNotifications({
						errors: ['Senhas não conferem'],
					});
				}
				if (
					(values.novaSenhaMainframe !== undefined &&
						values.novaSenhaMainframe?.length <= 3) ||
					(values.confirmaNovaSenhaMainframe !== undefined &&
						values.confirmaNovaSenhaMainframe?.length <= 3)
				) {
					return addNotifications({
						errors: [
							'Digite sua senha: Erro de Validação: O Valor é menor do que o mínimo permitido de 3',
						],
					});
				}
				if (values.idOrgao === 1) {
					return dispatch(
						alteraSenhaRequest({
							usuario: values.idMainframe,
							senhaAtual: values.senhaMainframe,
							senhaNova: values.novaSenhaMainframe,
							senhaNovaConfirma: values.confirmaNovaSenhaMainframe,
						}),
					);
				}
				return dispatch(
					alteraSenhaDetranRequest({
						usuario: values.idMainframe,
						senhaAtual: values.senhaMainframe,
						senhaNova: values.novaSenhaMainframe,
						senhaNovaConfirmacao: values.confirmaNovaSenhaMainframe,
						enderecoIp: enderecoIp.data ?? '0:0:0:0:0:0:0:1',
					}),
				);
			}
			if (
				values.novaSenhaMainframe !== undefined &&
				values.novaSenhaMainframe?.length <= 3
			) {
				return addNotifications({
					errors: [
						'Digite sua senha: Erro de Validação: O Valor é menor do que o mínimo permitido de 3',
					],
				});
			}

			return dispatch(
				salvaSenhaUsuarioRequest({
					idUsuarioMainframe: values.idMainframe,
					idLocalidade: values.idLocal ? values.idLocal.toString() : '',
					cpf: loginUnico.user.cpf,
					senha: loginUnico.renovacaoMainframe,
					senhaMainframe: values.novaSenhaMainframe,
				}),
			);
		},
		[
			dispatch,
			enderecoIp.data,
			errors.length,
			getLoginData,
			loginUnico.renovacaoMainframe,
			loginUnico.user.cpf,
		],
	);

	// PROSSEGUE POS CERTIFICADO

	const handleCleanSteps = useCallback(() => {
		// LIMPA PASSO 1
		if (alteraSenhaDetran.status !== 0) {
			dispatch(alteraSenhaDetranClear({}));
		}
		if (alteraSenha.status !== 0) {
			dispatch(alteraSenhaClear({}));
		}
		if (salvaSenhaUsuario.status !== 0) {
			dispatch(salvaSenhaUsuarioClear());
		}
		// LIMPA PASSO 3
		if (logon.status !== 0) {
			dispatch(logonClear({}));
		}
		if (logonRest.status !== 0) {
			dispatch(logonRestClear({}));
		}
	}, [
		alteraSenhaDetran,
		alteraSenha,
		salvaSenhaUsuario,
		logon,
		logonRest,
		dispatch,
	]);

	const clearPasswordFields = useCallback(
		(formik: FormikProps<NovoMenuOrgaoFormData>) => {
			formik.setFieldValue('novaSenhaMainframe', '');
			formik.setFieldValue('confirmaNovaSenhaMainframe', '');
		},
		[],
	);

	const handleChangeLocalidade = useCallback(
		(idLocalOnchange: number, formik: FormikProps<NovoMenuOrgaoFormData>) => {
			clearPasswordFields(formik);
			handleMainframe(idLocalOnchange, formik.values.idOrgao);
			setFormData(prev => ({
				...prev,
				idLocal: idLocalOnchange,
				idMainframe: '',
			}));
			setMainframeOptions([]);
			if (errors.length) {
				dispatch(clearNotifications());
			}
		},
		[clearPasswordFields, dispatch, errors.length, handleMainframe],
	);

	const handleChangeMainframe = useCallback(
		(idMainframe: number, formik: FormikProps<NovoMenuOrgaoFormData>) => {
			const mainframeSelected =
				(mainframeOptions.length &&
					mainframeOptions.filter(
						item => item.value === idMainframe.toString(),
					)) ||
				[];

			const mainframeSelectedPassword =
				(mainframeSelected.length && mainframeSelected[0].senha) || '';

			clearPasswordFields(formik);
			setFormData(prev => ({
				...prev,
				idMainframe: idMainframe.toString(),
				senhaMainframe: mainframeSelectedPassword,
			}));
			handleCleanSteps();
			if (errors.length) {
				dispatch(clearNotifications());
			}
		},
		[
			clearPasswordFields,
			dispatch,
			errors.length,
			handleCleanSteps,
			mainframeOptions,
		],
	);

	// AÇÕES APÓS SALVAR A SENHA DO USUÁRIO
	useEffect(() => {
		if (
			(alteraSenhaDetran.status === 200 || alteraSenha.status === 200) &&
			(Number(menuInfo.id) === 1 || Number(menuInfo.id) === 2)
		) {
			let idMainframe: string = '';

			if (alteraSenhaDetran.status === 200 && Number(menuInfo.id) === 2) {
				dispatch(
					salvaSenhaUsuarioRequest({
						idUsuarioMainframe: alteraSenhaDetran.form?.usuario
							? alteraSenhaDetran.form?.usuario
							: '',
						idLocalidade: formData.idLocal ? formData.idLocal.toString() : '',
						cpf: loginUnico.user.cpf,
						senha: loginUnico.renovacaoMainframe,
						senhaMainframe: alteraSenhaDetran.form?.senhaNova
							? alteraSenhaDetran.form?.senhaNova
							: '',
					}),
				);
				idMainframe = alteraSenhaDetran.form?.usuario ?? '';
				// MANTÉM O USUÁRIO MAINFRAME
				setFormData(prev => {
					return {
						...prev,
						idMainframe: idMainframe ?? '',
					};
				});
			} else if (alteraSenha.status === 200 && Number(menuInfo.id) === 1) {
				dispatch(
					salvaSenhaUsuarioRequest({
						idUsuarioMainframe: alteraSenha.form?.usuario
							? alteraSenha.form?.usuario
							: '',
						idLocalidade: formData.idLocal ? formData.idLocal.toString() : '',
						cpf: loginUnico.user.cpf,
						senha: loginUnico.renovacaoMainframe,
						senhaMainframe: alteraSenha.form?.senhaNova
							? alteraSenha.form?.senhaNova
							: '',
					}),
				);
				idMainframe = alteraSenha.form?.usuario ?? '';
				// MANTÉM O USUÁRIO MAINFRAME
				setFormData(prev => {
					return {
						...prev,
						idMainframe: idMainframe ?? '',
					};
				});
			}

			// LIMPA SERVIÇOS
			handleCleanSteps();
		}
	}, [
		alteraSenha,
		alteraSenhaDetran,
		dispatch,
		formData,
		loginUnico,
		handleCleanSteps,
		menuInfo.id,
	]);

	// AÇÕES APÓS ALTERAR A SENHA DO USUÁRIO
	useEffect(() => {
		if (
			salvaSenhaUsuario.status === 200 &&
			salvaSenhaUsuario.form?.idUsuarioMainframe === formData.idMainframe
		) {
			// CONTROLA O PASSO 1
			setIsPasswordUpdated(true);
			setNewPassword(salvaSenhaUsuario.data);
			// LIMPA OS MAINFRAMES SEM A SENHA
			setMainframeToUpdate(true);
			setMainframeUpdated(false);
			dispatch(limpaMainframe());
			// LIMPA SERVIÇOS
			handleCleanSteps();

			let idMainframe: string = '';
			if (salvaSenhaUsuario.status === 200)
				idMainframe = salvaSenhaUsuario.form?.idUsuarioMainframe ?? '';

			// MANTÉM O USUÁRIO MAINFRAME
			setFormData(prev => {
				return {
					...prev,
					idMainframe: idMainframe ?? '',
				};
			});
		}
	}, [dispatch, formData.idMainframe, handleCleanSteps, salvaSenhaUsuario]);

	// CHAMA O LOGON APÓS SALVAR SENHA
	useEffect(() => {
		if (
			isPasswordUpdated &&
			newPassword &&
			mainframeUpdated &&
			(logon.status === 0 || logonRest.status === 0) &&
			formData.idMainframe &&
			formData.senhaMainframe &&
			mainframe.status === 200
		) {
			// RESETA PASSO 1 E 2
			setIsPasswordUpdated(false);
			setMainframeUpdated(false);
			// CHAMA O PASSO 3
			handleLogon({
				...formData,
				idMainframe: newPassword.idUsuarioMainframe,
				senhaMainframe: newPassword.senhaMainframe,
			});
		}
	}, [
		formData,
		newPassword,
		handleLogon,
		isPasswordUpdated,
		logon.status,
		logonRest.status,
		mainframe.status,
		mainframeUpdated,
	]);

	return (
		<>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				key={menuInfo.id}
				initialValues={formData}
				validate={values => getValidationsErrorsMenu(values, schema)}
				onSubmit={values => handleSubmit(values)}
				enableReinitialize
			>
				{(formik: FormikProps<NovoMenuOrgaoFormData>) => {
					return (
						<Form id={String(menuInfo.id)} autoComplete="off">
							<Box>
								<Field
									as={ButtonImageOrgao}
									name={`idOrgao_${menuInfo.id}`}
									className="img imageInput btn-orgao"
									type="submit"
									value={menuInfo.id}
									src={menuInfo.id}
									alt={`Imagem do Órgão ${menuInfo.nome}`}
									disabled={logonRest.status === 100 || logon.status === 100}
								/>
								<BoxContent>
									<p>
										<strong>Localidade(s): *</strong>
									</p>
									<Field
										as={Select}
										name="idLocal"
										options={menuInfo.select}
										onChange={(v: number) => {
											handleChangeLocalidade(v, formik);
										}}
										error={!!formik.errors.idLocal}
										disabled={logonRest.status === 100 || logon.status === 100}
									/>
								</BoxContent>
								{formik.values.hasMainFrame && formik.values.idLocal && (
									<BoxContent>
										<p>
											<strong>Mainframe(s): *</strong>
										</p>
										<Field
											as={Select}
											name="idMainframe"
											options={mainframeOptions}
											onChange={(v: number) => {
												handleChangeMainframe(v, formik);
											}}
											error={!!formik.errors.validaIdMainframe}
											disabled={
												logonRest.status === 100 || logon.status === 100
											}
										/>
									</BoxContent>
								)}
								{(formik.values.idOrgao === 1 || formik.values.idOrgao === 2) &&
									formik.values.idMainframe !== '' &&
									(!formik.values.senhaMainframe ||
										(logon.status === 400 &&
											logon.mensagem.trim() ===
												'Codigo e/ou Password Invalido.') ||
										(logonRest.status === 400 &&
											logonRest.mensagem.trim() ===
												'Codigo e/ou Password Invalido.')) && (
										// eslint-disable-next-line react/jsx-indent
										<>
											<BoxContent>
												<p>
													<strong>
														Digite sua senha{' '}
														{formik.values.idOrgao === 1 ? 'IIRGD' : ''}
														{formik.values.idOrgao === 2 ? 'DETRAN' : ''}: *
													</strong>
												</p>
												<Field
													as={Input}
													id={`novaSenhaMainframe-${formik.values.idMainframe}-${formik.values.idOrgao}`}
													name="novaSenhaMainframe"
													autoComplete="new-password"
													defaultValue=""
													type="password"
													maxLength="10"
													error={!!formik.errors.novaSenhaMainframe}
												/>
												<LightButton
													text="Salvar"
													type="button"
													buttonColor="default"
													icon="save"
													onClick={() => handleNovaSenha(formik.values)}
													disabled={
														logonRest.status === 100 || logon.status === 100
													}
												/>
											</BoxContent>
										</>
									)}
								{((logon.status === 200 && logon.mensagem === 'NOVAPASSWORD') ||
									(logonRest.status === 200 &&
										logonRest.mensagem === 'NOVAPASSWORD')) &&
									formik.values.idMainframe !== '' && (
										<>
											<BoxContent>
												<p>
													<strong>Nova Senha : *</strong>
												</p>
												<TooltipInput>
													<Field
														as={Input}
														id={`novaSenhaMainframe-${formik.values.idMainframe}-${formik.values.idOrgao}`}
														name="novaSenhaMainframe"
														autoComplete="new-password"
														defaultValue=""
														type="password"
														maxLength="10"
														error={!!formik.errors.novaSenhaMainframe}
													/>
													<TooltipInfo
														title="Nova Senha"
														content="A nova senha deve conter letras e números e no máximo 10 caracteres."
													/>
												</TooltipInput>
											</BoxContent>
											<BoxContent>
												<p>
													<strong>Confirmar Senha : *</strong>
												</p>
												<TooltipInput>
													<Field
														as={Input}
														name="confirmaNovaSenhaMainframe"
														autoComplete="new-password"
														defaultValue=""
														type="password"
														maxLength="10"
														error={!!formik.errors.confirmaNovaSenhaMainframe}
													/>
													<TooltipInfo
														title="Confirme a Senha"
														content="Repita a senha digitada no campo acima."
													/>
												</TooltipInput>
												<LightButton
													text="Salvar"
													type="button"
													buttonColor="default"
													icon="save"
													onClick={() =>
														handleNovaSenha(formik.values, 'confirmacao')
													}
													disabled={
														logonRest.status === 100 || logon.status === 100
													}
												/>
											</BoxContent>
										</>
									)}
							</Box>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default MenuItem;
