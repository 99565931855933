import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirMunicipioRequest } from './types';

export function excluirMunicipioRequest(
	token: string,
	data: ExcluirMunicipioRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_REQUEST,
		token,
		payload: data,
	};
}
export function excluirMunicipioSuccess(payload: object): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_SUCCESS,
		payload,
	};
}
export function excluirMunicipioFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_FAILURE,
		payload: null,
	};
}
export function excluirMunicipioClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_CLEAR,
		payload: null,
	};
}
