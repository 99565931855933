import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { limparMascara, treatObjNullValues } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ConsultarMutuarioData } from './types';

// ACTIONS
import {
	IRequestConsultarMutuario,
	consultarMutuarioSuccess,
	consultarMutuarioFailure,
} from './actions';
import { consultarPrestacoesAtrasadasRequest } from '../consultarPrestacoesAtrasadas/actions';

function* consultarMutuarioRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: IRequestConsultarMutuario } = request;

	const statusAllowed = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`mutuarios/${payload.numeroContrato}`,
		undefined,
		undefined,
		undefined,
		undefined,
		undefined,
		statusAllowed,
	);

	if (response.status === 200 || response.status === 204) {
		if (response.status === 200) {
			const data = treatObjNullValues(response.data) as ConsultarMutuarioData;

			data.celular = limparMascara(data.celular);
			data.telefone1 = limparMascara(data.telefone1);
			data.telefone2 = limparMascara(data.telefone2);
			data.telefone3 = limparMascara(data.telefone3);

			yield put(consultarMutuarioSuccess(data));
			const date = new Date();
			const dataLimite = `${date.getFullYear()}-${
				date.getMonth() + 1
			}-${date.getDate()}`;

			const isToken = token || '';

			yield put(
				consultarPrestacoesAtrasadasRequest(isToken, {
					dataLimite,
					numeroContrato: Number(response.data.numeroContrato),
				}),
			);
		} else {
			yield put(consultarMutuarioSuccess(response));
		}
	} else {
		yield put(consultarMutuarioFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTAR_MUTUARIO_REQUEST, consultarMutuarioRequest),
]);
