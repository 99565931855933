import { ReducerActionRotaSP } from 'store/modules/types';

import { Types, ExcluirMunicipioIbgeRequest } from './types';

export function excluirMunicipioIbgeRequest(
	token: string,
	data: ExcluirMunicipioIbgeRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_REQUEST,
		token,
		payload: data,
	};
}
export function excluirMunicipioIbgeSuccess(
	payload: object,
): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_SUCCESS,
		payload,
	};
}
export function excluirMunicipioIbgeFailure(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_FAILURE,
		payload: null,
	};
}
export function excluirMunicipioIbgeClear(): ReducerActionRotaSP {
	return {
		type: Types.EXCLUIR_MUNICIPIO_IBGE_CLEAR,
		payload: null,
	};
}
