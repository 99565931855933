import React, { useState, useEffect, useCallback } from 'react';

import { Row, Col } from 'antd';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_IIRGD } from 'routes/paths';
import {
	ROUTE_IIRGD_INCLUSAO_OBSERVACOES,
	ROUTE_IIRGD_LEITURA_DIGITAIS,
	ROUTE_IIRGD_STATUS,
} from 'pages/iirgd/RgFicDigital/routes/paths';

// REDUX
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import {
	alterarObservacoesRequest,
	alterarObservacoesSuccess,
} from 'store/modules/api/iirgd/alterarObservacoes/actions';
import { biometriaClear } from 'store/modules/api/biometria/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Alert from 'components/Common/Notifications/Alert';

// UTILS
import {
	calcularIdade,
	completaZeroEsquerda,
	limparMascara,
	unformatDate,
	verificarMaioridade,
} from 'utils/genericFunctions';

// COMPONENTS
// import { consultaPesquisaCidadaoSelecionadoClear } from 'store/modules/api/iirgd/consultaPesquisaCidadao/actions';
import { dareKitRequest } from 'store/modules/api/gerarDare/actions';
import {
	consultarDigitaisClear,
	consultarDigitaisRequest,
} from 'store/modules/api/utils/consultarDigitais/actions';
import { confrontarDigitaisClear } from 'store/modules/api/utils/confrontarDigitais/actions';
import { capturarBiometriaClear } from 'store/modules/api/biometria/capturarBiometria/actions';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';
import { geraSenhaClear } from 'store/modules/api/relatorios/geraSenha/actions';
import { lockUrl } from 'store/modules/api/sgu/loginUnico/actions';
import { verificarVersaoServicoBiometriaClear } from 'store/modules/api/biometria/verificarVersaoServicoBiometria/actions';

import { DadosPrincipais } from './Sections/DadosPrincipais';
import { Observacoes } from './Sections/Observacoes';
import { Questionario } from './Sections/Questionario';
import { H2 } from './styled';
import { IFormCadastroRg } from '../CadastroRg/form';

const ConferenciaDados: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);
	const [hasSubmit, setHasSubmit] = useState<boolean>(false);

	const [primeiraVia, setPrimeiraVia] = useState<boolean | null>(null);
	const [maioridade, setMaioridade] = useState<boolean | null>(null);

	const [dareVinculada, setDareVinculada] = useState<string>('');

	const { gerarDare } = useSelector((state: ApplicationState) => state.api);

	const { atualizarDare } = useSelector(
		(state: ApplicationState) => state.api.dare,
	);

	const { consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	useEffect(() => {
		dispatch(capturarBiometriaClear());
		dispatch(consultarDigitaisClear());
		dispatch(confrontarDigitaisClear());
		dispatch(geraSenhaClear());
		dispatch(verificarVersaoServicoBiometriaClear());
	}, [dispatch]);

	const {
		alterarObservacoes,
		cadastroRg,
		consultaPesquisaCriminal,
		consultaPesquisaCidadao,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	useEffect(() => {
		if (!cadastroRg.form.principal) {
			history.push(ROUTE_IIRGD);
		}
	}, [cadastroRg.form.principal, history]);

	// Seta mensagem a DARE vinculada ao RG.
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (cadastroRg.form.principal && (atualizarDare.data || gerarDare.data)) {
			const { numeroRg } = cadastroRg.form.principal;

			if (atualizarDare.data) {
				return setDareVinculada(atualizarDare.data.mensagem);
			}
			if (gerarDare.data) {
				return setDareVinculada(
					`DARE: ${gerarDare.data.numeroControleGuiaMae} foi vinculada ao RGE/CIN: ${numeroRg}.`,
				);
			}
		}

		setDareVinculada('');
	}, [cadastroRg.form.principal, atualizarDare.data, gerarDare.data]);

	useEffect(() => {
		if (!hasSubmit) {
			dispatch(biometriaClear());
			dispatch(consultarDigitaisClear());
			dispatch(alterarObservacoesSuccess(0));
		}
	}, [hasSubmit, dispatch]);

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	// ESTATÍSTICA DARE VINCULADA
	useEffect(() => {
		if (atualizarDare.status === 200 && atualizarDare?.numeroDare) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'VINCULACAO_DARE',
				idMotivo: 16,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao: atualizarDare?.numeroDare.toString() ?? '',
			};
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [cadastroRg, dispatch, atualizarDare, login, atendimento]);

	// Verifica maioridade do cidadão.
	useEffect(() => {
		const { tipo, form } = cadastroRg;
		const { principal } = form;

		setPrimeiraVia(tipo.primeiraVia);

		if (principal && verificarMaioridade(principal.dataNascimento))
			setMaioridade(true);

		if (principal) setHasLoaded(true);
	}, [cadastroRg]);

	// Realiza o redirecionamento para Leitura de Digitais.
	useEffect(() => {
		if (
			hasSubmit &&
			alterarObservacoes.status === 200 &&
			consultarDigitais?.data &&
			consultarDigitais?.status === 200 &&
			consultarDigitais?.data.dadosBiometricos.length &&
			cadastroRg.form.principal?.dni !== '10977'
		) {
			history.push(ROUTE_IIRGD_LEITURA_DIGITAIS);
		} else if (
			(consultarDigitais?.status !== 0 &&
				consultarDigitais?.status !== 100 &&
				consultarDigitais?.status !== 200) ||
			(consultarDigitais?.status !== 0 &&
				consultarDigitais?.status !== 100 &&
				cadastroRg.form.principal?.dni === '10977')
		) {
			dispatch(lockUrl(true));
			history.push(ROUTE_IIRGD_STATUS);
		} else if (
			hasSubmit &&
			alterarObservacoes.status === 200 &&
			consultarDigitais?.data &&
			consultarDigitais?.status === 200 &&
			!consultarDigitais?.data.dadosBiometricos.length
		) {
			const payload: IncluirRequest = {
				descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
				tipoDocumento: 'RG',
				numeroDocumento: cadastroRg.form.principal?.numeroRg ?? '',
				codigoLocalidade: Number(login.user.posto),
				idOrgao: login.user.idOrgao,
				cpf: login.user.cpf,
				descricaoServico: 'BIOMETRIA_NAO_DISPONIVEL',
				idMotivo: 0,
				numeroProcesso: atendimento.salvarAtendimento.data?.idAtendimento,
				observacao:
					'O Serviço de consulta de digitais não retornou nenhum dedo para sorteio.',
			};
			dispatch(estatisticaIncluirRequest(payload));
			dispatch(lockUrl(true));
			history.push(ROUTE_IIRGD_STATUS);
		}
	}, [
		hasSubmit,
		consultarDigitais,
		history,
		alterarObservacoes.status,
		cadastroRg,
		login.user.posto,
		login.user.idOrgao,
		login.user.cpf,
		atendimento,
		dispatch,
	]);

	// Realiza o redirecionamento para Status do Cadastro
	// ou realiza a consulta das digitais para confronto.
	useEffect(() => {
		if (hasSubmit && alterarObservacoes.status === 200) {
			if (
				cadastroRg.form.principal &&
				(typeof cadastroRg.form.principal.entrega === 'number' ||
					cadastroRg.form.principal.entrega === 'K')
			) {
				if (typeof cadastroRg.form.principal.entrega === 'number') {
					dispatch(
						dareKitRequest({
							finalizados: 1,
							correios: cadastroRg.form.principal?.sedex,
						}),
					);
				} else {
					dispatch(dareKitRequest({ finalizados: 1 }));
				}
			}
			if (
				consultaPesquisaCidadao.selecionado?.afis === '*' &&
				cadastroRg.form.principal &&
				!cadastroRg.perguntas.teveAlteracao
			) {
				const cpf = cadastroRg.form.principal.cpf
					? limparMascara(cadastroRg.form.principal.cpf)
					: '';

				const { salvarAtendimento } = atendimento;

				dispatch(
					consultarDigitaisRequest({
						evento: {
							id_atendimento: salvarAtendimento.data?.idAtendimento
								? salvarAtendimento.data?.idAtendimento
								: '',
							id_cidadao: salvarAtendimento.data?.idCidadao
								? salvarAtendimento.data?.idCidadao
								: '',
							cpf: '',
							identificacao: cpf,
							ip: loginUnico.login.user.ip,
							canal: {
								id: 1,
								desc: 'presencial',
								estacao: {
									id: 0,
									desc: 'string',
								},
								localidade: {
									id: loginUnico.login.user.idLocal,
									desc: loginUnico.login.user.nomePosto,
								},
							},
						},
						idAtendimento: salvarAtendimento.data?.idAtendimento
							? salvarAtendimento.data?.idAtendimento
							: '',
						cpf: '',
						rgSp: cadastroRg.form.principal.numeroRg,
						dataNascimento: unformatDate(
							cadastroRg.form.principal.dataNascimento,
						),
						finalidade: 'REUSO_RG',
						incluirPendentes: true,
					}),
				);
			} else if (
				cadastroRg.tipo.primeiraVia ||
				cadastroRg.config.acervo ||
				cadastroRg.tipo.segundaVia
			) {
				dispatch(lockUrl(true));
				history.push(ROUTE_IIRGD_STATUS);
			}
		}
	}, [
		alterarObservacoes.status,
		atendimento,
		cadastroRg,
		consultaPesquisaCidadao.selecionado,
		consultaPesquisaCriminal.restricaoCriminal,
		dispatch,
		hasSubmit,
		history,
		loginUnico.login.user.idLocal,
		loginUnico.login.user.ip,
		loginUnico.login.user.nomePosto,
	]);

	const handleSubmit = useCallback(() => {
		if (alterarObservacoes.temp) {
			setHasSubmit(true);

			let novoNumeroDocumentoResponsavel = '';
			if (
				alterarObservacoes.temp.tipoDocumentoResponsavel === 'CTPS' &&
				alterarObservacoes.temp.numeroDocumentoResponsavel &&
				alterarObservacoes.temp.numeroDocumentoResponsavel?.length <= 18
			) {
				novoNumeroDocumentoResponsavel = completaZeroEsquerda(
					alterarObservacoes.temp.numeroDocumentoResponsavel,
					18,
				);
			} else if (
				alterarObservacoes.temp.tipoDocumentoResponsavel === 'CNH' &&
				alterarObservacoes.temp.numeroDocumentoResponsavel &&
				alterarObservacoes.temp.numeroDocumentoResponsavel?.length < 12
			) {
				novoNumeroDocumentoResponsavel = completaZeroEsquerda(
					alterarObservacoes.temp.numeroDocumentoResponsavel,
					11,
				);
			}

			const values = {
				...alterarObservacoes.temp,
				cr: !!alterarObservacoes.temp.cr,
				grauParentescoSp: alterarObservacoes.temp?.grauParentescoSp || '',
				numeroDocumentoResponsavel:
					novoNumeroDocumentoResponsavel ||
					alterarObservacoes.temp.numeroDocumentoResponsavel,
				ctpsObs: alterarObservacoes.temp.ctpsObs || '',
				srObs: alterarObservacoes.temp.srObs || '',
				estObs: alterarObservacoes.temp.estObs || '',
			};

			if (values.tipoDocumentoResponsavel !== 'CTPS') {
				values.ctpsSerie = '';
				values.ctpsSerieUf = '';
				values.cptsNum = '';
			}
			values.cpfAtendente = login.user.cpf;

			values.observacao = alterarObservacoes.temp.observacao
				? alterarObservacoes.temp.observacao.toLocaleUpperCase()
				: '';

			values.rgRecolhidoPor = alterarObservacoes.temp.rgRecolhidoPor
				? alterarObservacoes.temp.rgRecolhidoPor
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLocaleUpperCase()
				: '';

			if (values.rgForaSpEmissao.indexOf('-') === -1) {
				values.rgForaSpEmissao = unformatDate(values.rgForaSpEmissao);
			}

			if (values.dataExpedicaoDocumentoResponsavel.indexOf('-') === -1) {
				values.dataExpedicaoDocumentoResponsavel = unformatDate(
					values.dataExpedicaoDocumentoResponsavel,
				);
			}

			const principal: IFormCadastroRg | null =
				cadastroRg.form.principal ?? null;

			if (cadastroRg.info.lote && cadastroRg.info.lote !== null) {
				values.lote = cadastroRg.info.lote;
			}

			if (calcularIdade(cadastroRg.info.dataNascimento) > 15) {
				values.numeroDocumentoResponsavel = '';
				values.dataExpedicaoDocumentoResponsavel = '';
				values.tipoDocumentoResponsavel = '';
				values.ufDocumentoResponsavel = '';
			}

			dispatch(alterarObservacoesRequest(values, cadastroRg.dare, principal));
		}
	}, [alterarObservacoes.temp, cadastroRg, dispatch, login.user.cpf]);

	const handleReeditarCadastro = useCallback(() => {
		history.push(ROUTE_IIRGD_INCLUSAO_OBSERVACOES);
	}, [history]);

	const handleButtonVoltar = useCallback(() => {
		history.push(ROUTE_IIRGD_INCLUSAO_OBSERVACOES);
	}, [history]);

	return (
		<>
			{hasLoaded && (
				<>
					{dareVinculada && (
						<Row gutter={[0, 20]}>
							<Col span={24}>
								<Alert
									type="info"
									message={<p>{dareVinculada}</p>}
									onClose={() => setDareVinculada('')}
								/>
							</Col>
						</Row>
					)}

					<Section title="Protocolo para Simples Conferência de Dados">
						{consultaPesquisaCriminal.restricaoCriminal && (
							<Row justify="end">
								<Col span={5}>
									<H2>CRV</H2>
								</Col>
							</Row>
						)}

						<DadosPrincipais />

						<Observacoes />

						{primeiraVia && <>{maioridade && <Questionario />}</>}

						<Row gutter={[0, 20]}>
							<Col span={24} />
						</Row>

						<Row justify="center">
							<Col>
								<ButtonImage
									type="button"
									src="reeditar-cadastro"
									onClick={handleReeditarCadastro}
								/>
							</Col>

							<Col offset={1}>
								<ButtonImage
									type="button"
									src="salvar-cadastro"
									onClick={handleSubmit}
								/>
							</Col>
						</Row>
					</Section>

					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</>
			)}
		</>
	);
};

export default ConferenciaDados;
